import { Chip, ChipProps, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';

import { theme } from '@app/theme';
import { Product, ProductStatus, ProductSubStatus } from '@app/types/catalog';
import { getSubStatusLabel } from '@app/utils/catalog';

export interface ProductStatusLabelProps extends ChipProps {
  product: Product;
}
export function ProductStatusLabel({
  product,
  sx,
}: ProductStatusLabelProps): ReactElement {
  const color = useMemo(() => {
    switch (product.publication.status) {
      case ProductStatus.DRAFT:
        switch (product.customFields?.subStatus) {
          case ProductSubStatus.RECRUITING:
          case ProductSubStatus.CONFIRM:
          case ProductSubStatus.SUPPLY_CLOSED:
            return theme.palette.tertiary.main;
          case ProductSubStatus.DEMAND_CLOSED:
            return theme.palette.secondary.main;
        }
        return theme.palette.error.main;
      case ProductStatus.ACTIVE:
        return theme.palette.tertiary.main;
      case ProductStatus.ARCHIVED:
        return theme.palette.secondary.main;
    }
    return theme.palette.secondary.main;
  }, [product]);

  return (
    <Chip
      variant="outlined"
      size="small"
      label={
        <Typography variant="body2">{getSubStatusLabel(product)}</Typography>
      }
      sx={{ borderColor: color, borderRadius: 1, color, ...sx }}
    />
  );
}
