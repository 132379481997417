import { format as dateFnsFormat } from 'date-fns';
import dateFnsJa from 'date-fns/locale/ja';

export function dateFormat(
  value?: string | Date,
  format = 'yyyy年MM月dd日',
  convertJp = false
) {
  if (!value) return '';

  let date = typeof value === 'string' ? new Date(value) : value;
  if (isNaN(date.getDate())) return '';

  if (convertJp) {
    date = convertUtcToJp(date);
  }
  return dateFnsFormat(date, format, {
    locale: dateFnsJa,
  });
}

/**
 * UTCを日本時間に変換する
 */
export function convertUtcToJp(date?: number | string | Date): Date {
  if (!date) return new Date();
  const d = (
    toString.call(date).slice(8, -1) === 'Date' ? date : new Date(date)
  ) as Date;
  // 日本時間 (UTC+9) に変換
  const offset = 9 * 60 * 60 * 1000; // 9時間をミリ秒に変換
  // UTC time
  const utcTime = d.getTime() + d.getTimezoneOffset() * 60 * 1000; // convert to UTC time
  // add Japan offset
  return new Date(utcTime + offset);
}

/**
 * 曜日を"月曜・火曜・水曜"形式にフォーマットする。
 * @param week 曜日の数値配列
 * @param separate 曜日の区切り文字
 * @returns "月曜・火曜・水曜"形式
 */
export function formatWeek(week?: number[], separate = '・') {
  if (!week?.length) {
    return '';
  }
  return week
    ?.map(
      (w) =>
        `${dateFnsJa.localize?.day(w, {
          width: 'short',
        })}曜`
    )
    .join(separate);
}

/**
 * 時間(時)の選択肢を取得する
 */
export function getTimeHourItems(): string[] {
  return Array.from({ length: 24 }, (h, i) => i.toString().padStart(2, '0'));
}

/**
 * 時間(分)の選択肢を取得する
 */
export function getTimeMinItems(interval = 5): string[] {
  return Array.from({ length: 60 / interval }, (h, i) =>
    (i * 5).toString().padStart(2, '0')
  );
}
