import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from '@mui/material';
import { ReactElement } from 'react';

export interface SimpleDialogProps extends Omit<DialogProps, 'title'> {
  cancelLabel?: string;
  disabled?: boolean;
  hideContent?: boolean;
  loading?: boolean;
  onClickCancel?: () => void;
  onClickSubmit?: () => void;
  submitLabel?: string;
  title?: string | ReactElement;
}

export function SimpleDialog({
  cancelLabel,
  children,
  disabled = false,
  hideContent = false,
  loading,
  onClickCancel,
  onClickSubmit,
  submitLabel,
  title,
  ...props
}: SimpleDialogProps): ReactElement {
  const handleClose = () => {
    onClickCancel?.();
  };

  const handleSubmit = () => {
    onClickSubmit?.();
  };

  return (
    <Dialog onClose={handleClose} {...props}>
      {!!title && (
        <Typography variant="h6" fontWeight={500} px={4} py={3}>
          {title}
        </Typography>
      )}
      {!!children && !hideContent && (
        <DialogContent sx={{ px: 4, py: 1 }}>{children}</DialogContent>
      )}
      {!!(cancelLabel || submitLabel) && (
        <DialogActions sx={{ px: 4, py: 3 }}>
          {cancelLabel && (
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              {cancelLabel}
            </Button>
          )}
          {submitLabel && (
            <LoadingButton
              variant="contained"
              color="primary"
              disabled={disabled}
              loading={loading}
              onClick={handleSubmit}
            >
              {submitLabel}
            </LoadingButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
