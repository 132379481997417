import { Box, Button, TextField, TextFieldProps } from '@mui/material';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { parseISO } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { forwardRef, ForwardedRef } from 'react';

import { dateFormat } from '@app/utils/format';

export declare type PickersActionBarAction =
  | 'clear'
  | 'cancel'
  | 'accept'
  | 'today';

const CustomActionBar = (props: PickersActionBarProps) => {
  const { onClear, actions } = props;
  const resolvedActions = Array.isArray(actions) ? actions : [];
  return (
    <Box textAlign="end" px={3} pb={1}>
      {resolvedActions?.includes('clear') && (
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={onClear}
        >
          クリア
        </Button>
      )}
    </Box>
  );
};

export interface DatePickerProps
  extends Omit<TextFieldProps, 'value' | 'onChange'> {
  actions?: PickersActionBarAction[];
  datePicker?: Omit<
    MuiDatePickerProps<Date | string, Date | string>,
    'renderInput' | 'onChange'
  >;
  disabled?: boolean;
  format?: string;
  helperText?: string;
  onChange?: (value?: string) => void;
  readOnly?: boolean;
  value?: string;
}

export const DatePicker = forwardRef(function (
  {
    actions = [],
    datePicker,
    disabled = false,
    format = 'yyyy/MM/dd',
    helperText,
    onChange,
    error = false,
    readOnly = false,
    placeholder,
    size = 'medium',
    sx,
    value,
  }: DatePickerProps,
  ref: ForwardedRef<unknown>
) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <MuiDatePicker
        value={parseISO(value || '')}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            size={size}
            helperText={helperText}
            inputProps={{
              ...params.inputProps,
              placeholder: placeholder || dateFormat(new Date(), format),
              readOnly,
              value: dateFormat(value, format),
            }}
            sx={{ pr: 0, width: '200px', ...sx }}
          />
        )}
        componentsProps={{
          actionBar: {
            actions,
          },
        }}
        components={{
          ActionBar: CustomActionBar,
        }}
        onChange={(e) => {
          if (e !== 'Invalid Date') {
            onChange?.(dateFormat(e || '', format));
          }
        }}
        {...datePicker}
      />
    </LocalizationProvider>
  );
});
