import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { categoryByActiveSelector } from '@app/domain/catalog';
import {
  OrientationApplyForm as Form,
  OrientationApplyFormData as FormData,
  OrientationApplyFormDefaultValues as DefaultValue,
} from '@app/schemas/catalog';
import {
  CategoryName as Category,
  Product,
  ProductOrientationTypeKey as OrientationTypeKey,
  ProductUpdate,
  ProductSchedule,
} from '@app/types/catalog';

export const useOrientationApplyForm = (
  orientationType?: OrientationTypeKey
) => {
  const categories = useRecoilValue(categoryByActiveSelector);
  const form = useForm<FormData>(Form);

  /**
   * データをフォームにセットする
   */
  const setFormValues = (data?: Partial<Product>) => {
    const customFields = data?.customFields || {};
    form.reset({
      parentId: customFields.parentId,
      publication: data?.publication || DefaultValue.publication,
      schedules:
        customFields.schedules?.map((schedule) => {
          return {
            date: schedule.date || DefaultValue.schedules[0].date,
            occupations:
              schedule.occupations || DefaultValue.schedules[0].occupations,
          };
        }) || DefaultValue.schedules,
      subStatus: customFields.subStatus || DefaultValue.subStatus,
    });
  };

  /**
   * 登録（更新）するデータをフォームから取得する
   */
  const getPayload = (): ProductUpdate => {
    const categoryId =
      categories.find((c) => c.name === Category.ORIENTATION_SUB)?.id || '';
    const values = form.getValues();
    return {
      categoryId,
      customFields: {
        category: Category.ORIENTATION_SUB,
        orientationType,
        parentId: values.parentId,
        schedules: values.schedules
          .map((schedule) => {
            const occupations = schedule.occupations || [];
            return occupations.length
              ? { date: schedule.date, occupations }
              : undefined;
          })
          .filter((i) => !!i) as ProductSchedule[],
        subStatus: values.subStatus,
      },
      name: '説明会応募',
      publication: {
        since: values.publication.since || undefined,
        status: values.publication.status,
        until: values.publication.until || undefined,
      },
      variants: [
        {
          price: { amount: 0 },
          title: '',
        },
      ],
    };
  };

  return {
    form,
    getPayload,
    setFormValues,
  };
};
