import { Card, Stack, Typography } from '@mui/material';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { getAdminProducts } from '@app/adapter/catalog-service';
import { getOrdersByOrgId, GetOrdersOption } from '@app/adapter/order-service';
import { StatusEditDialog } from '@app/administrator/components/Order/StatusEditDialog';
import { DetailButton } from '@app/components/Shared/Button/DetailButton';
import { EditIconText } from '@app/components/Shared/Button/EditIconText';
import { FormDateField } from '@app/components/Shared/Inputs/FormDateField';
import { FormSelect } from '@app/components/Shared/Inputs/FormSelect';
import { ListTable, paginationModel } from '@app/components/Shared/ListTable';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { SearchTextBox } from '@app/components/Shared/SearchTextBox';
import { organization } from '@app/domain/organization';
import {
  OrderSearchForm as SearchForm,
  OrderSearchFormData as SearchFormData,
} from '@app/schemas/order';
import {
  Product,
  ProductSchedule as Schedule,
  ScheduleTime,
} from '@app/types/catalog';
import { Order } from '@app/types/order';
import { isError } from '@app/utils/error';
import { dateFormat } from '@app/utils/format';
import { getOrderStatusLabel, statusOptions } from '@app/utils/order';

export function OrientationIndex() {
  const navigate = useNavigate();
  const currentOrg = useRecoilValue(organization);

  const [isLoading, setIsLoading] = useState(false);
  const [isStatusModal, setStatusModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Order>();

  const form = useForm<SearchFormData>(SearchForm);
  const { control } = form;
  const formWatch = useWatch({ control });
  const [keyword, setKeyword] = useState('');

  const [rows, setRows] = useState<Order[]>([]);
  const [rowTotal, setRowTotal] = useState<number>(0);
  const [currentPaginationModel, setCurrentPaginationModel] =
    useState<GridPaginationModel>(paginationModel);

  const fetchOrders = useCallback(
    async (option?: GetOrdersOption) => {
      if (!currentOrg) return;

      try {
        const { data } = await getOrdersByOrgId(currentOrg.id, {
          ...option,
          filter: {
            ...option?.filter,
          },
        });
        return data;
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
      }
    },
    [currentOrg]
  );

  const rowRefresh = useCallback(async () => {
    setIsLoading(true);
    const data = await fetchOrders({
      expand: 'customer.user,lineItems.product',
      filter: {
        keyword,
      },
      ...currentPaginationModel,
    });

    let parentProducts: Product[] = [];
    if (currentOrg && data?.value?.length) {
      const { data: parentData } = await getAdminProducts(currentOrg.id, {
        filter: {
          id: data.value
            .map(
              (v) =>
                (v.lineItems[0]?.product as Product)?.customFields?.parentId
            )
            .filter((i) => !!i) as string[],
        },
        pageSize: currentPaginationModel.pageSize,
      });
      parentProducts = parentData?.value || [];
    }

    setRows(
      data?.value?.length
        ? data.value.map((order) => {
            const lineItem = order.lineItems[0];
            const product = lineItem?.product as Product;
            const parentProduct = parentProducts.find(
              (p) => p.id === product?.customFields?.parentId
            );
            return {
              ...order,
              lineItems: lineItem
                ? [
                    {
                      ...lineItem,
                      product: {
                        ...product,
                        customFields: {
                          ...product?.customFields,
                          schedules: parentProduct?.customFields?.schedules,
                        },
                        name: parentProduct?.name || '',
                      },
                    },
                  ]
                : [],
            };
          })
        : []
    );
    setRowTotal(data?.total || 0);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPaginationModel, formWatch, keyword]);

  useEffect(() => {
    void rowRefresh();
  }, [rowRefresh]);

  const columns: GridColDef[] = [
    {
      field: 'productName',
      flex: 1,
      headerAlign: 'center',
      headerName: '説明会名',
      minWidth: 160,
      renderCell: ({ row }) => (
        <div className="text-ellipsis">
          {row.lineItems[0]?.product?.name || ''}
        </div>
      ),
      sortable: false,
    },
    {
      align: 'center',
      field: 'name',
      headerAlign: 'center',
      headerName: '応募者名',
      renderCell: ({ row }) => (
        <div className="text-ellipsis">{row.customer?.name || ''}</div>
      ),
      sortable: false,
      width: 150,
    },
    {
      align: 'center',
      field: 'date',
      headerAlign: 'center',
      headerName: '参加日付',
      renderCell: ({ row }) => (
        <div className="ow-break-word">
          {row.customFields?.dates
            .map((d: string) => dateFormat(d.split(' ')[0], 'M月d日'))
            .filter((i: string) => !!i)
            .join('\n')}
        </div>
      ),
      sortable: false,
      width: 100,
    },
    {
      align: 'center',
      field: 'time',
      headerAlign: 'center',
      headerName: '参加時間',
      renderCell: ({ row }) => (
        <div className="ow-break-word">
          {row.customFields?.dates
            .map((datetime: string) => {
              const date = datetime.split(' ')[0] || '';
              const time = datetime.split(' ')[1] || '';
              const scheduleTime =
                row.lineItems[0]?.product?.customFields?.schedules
                  ?.find((s: Schedule) => s.date === date)
                  ?.times?.find((t: ScheduleTime) => t.start === time);
              return scheduleTime
                ? `${scheduleTime.start}~${scheduleTime.end}`
                : '';
            })
            .filter((i: string) => !!i)
            .join('\n')}
        </div>
      ),
      sortable: false,
      width: 110,
    },
    {
      align: 'center',
      field: 'occupation',
      headerAlign: 'center',
      headerName: '希望職種',
      renderCell: ({ row }) =>
        row.customer?.user?.customFields?.desiredJob || '',
      sortable: false,
      width: 180,
    },
    {
      align: 'center',
      field: 'reason',
      headerAlign: 'center',
      headerName: '応募理由',
      renderCell: ({ row }) => (row.customFields?.reason ? 'あり' : 'なし'),
      sortable: false,
      width: 80,
    },
    {
      align: 'center',
      field: 'status',
      headerAlign: 'center',
      headerName: '参加状況',
      renderCell: ({ row }) => (
        <EditIconText
          label={getOrderStatusLabel(row.status)}
          onClick={() => {
            setSelectedItem(row);
            setStatusModal(true);
          }}
        />
      ),
      sortable: false,
      width: 90,
    },
    {
      align: 'center',
      field: 'action',
      headerAlign: 'center',
      headerName: '',
      renderCell: ({ row }) => (
        <DetailButton
          onClick={() =>
            navigate(
              `/orientation-orders/${row.customFields?.orientationGroupKey}`
            )
          }
        />
      ),
      sortable: false,
      width: 60,
    },
  ];

  return (
    <>
      <PageTitle title="説明会応募者一覧" buttonHidden />
      <Card>
        <Stack direction="row" spacing={1.5} p={1.5}>
          <SearchTextBox
            placeholder="キーワードで検索"
            value={keyword}
            onSubmit={setKeyword}
          />
          <FormProvider {...form}>
            <form style={{ width: '100%' }}>
              <Stack direction="row" spacing={1.5}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Typography variant="body3" whiteSpace="nowrap">
                    参加日付
                  </Typography>
                  <FormDateField name="scheduleDate" sx={{ width: 160 }} />
                </Stack>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Typography variant="body3" whiteSpace="nowrap">
                    参加状況
                  </Typography>
                  <FormSelect
                    name="status"
                    options={statusOptions}
                    placeholder="すべて"
                    sx={{ width: 110 }}
                  />
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
        <ListTable
          columns={columns}
          rows={rows}
          rowCount={rowTotal}
          rowHeight={76}
          loading={isLoading}
          paginationModel={currentPaginationModel}
          className="b-radius-top-less"
          initialState={{
            pagination: { paginationModel },
          }}
          onPaginationModelChange={setCurrentPaginationModel}
        />
      </Card>
      <StatusEditDialog
        open={isStatusModal}
        order={selectedItem}
        setOpen={setStatusModal}
        onClickSubmit={rowRefresh}
      />
    </>
  );
}
