import { LoadingButton } from '@mui/lab';
import { Box, ButtonProps, Typography, Stack } from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import {
  SimpleDialog,
  SimpleDialogProps,
} from '@app/components/Shared/Dialog/SimpleDialog';
import { FormCheckbox } from '@app/components/Shared/Inputs/FormCheckbox';
import { useOrientationApplyForm } from '@app/hooks/useOrientationApplyForm';
import { useOrientationProduct } from '@app/hooks/useOrientationProduct';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import {
  Product,
  ProductSubStatus as SubStatus,
  ProductSchedule as Schedule,
  ProductScheduleOccupation as ScheduleOccupation,
} from '@app/types/catalog';
import { getLocationLabel } from '@app/utils/catalog';
import { dateFormat } from '@app/utils/format';

export interface OrientationApplyButtonProps
  extends Omit<ButtonProps, 'onChange'> {
  label?: string;
  onChange?: (product?: Product) => void;
  parentProduct: Product;
  product?: Product;
}

export function OrientationApplyButton({
  label = '参加応募',
  onChange,
  parentProduct,
  product,
  ...props
}: OrientationApplyButtonProps): ReactElement {
  const setSnackbar = useSetSnackbar();
  const { isUpdating, setProduct, setUpdating, updateOrCreate } =
    useOrientationProduct();
  const { form, getPayload, setFormValues } = useOrientationApplyForm(
    parentProduct.customFields?.orientationType
  );
  const [isPublishModal, setPublishModal] = useState(false);

  const handleClickCancel = useCallback(() => {
    setPublishModal(false);
  }, [setPublishModal]);

  const handleClickSubmit = useCallback(async () => {
    setUpdating(true);
    try {
      await updateOrCreate({ ...getPayload() });
      setSnackbar(true, '保存しました', 'success');
      handleClickCancel();
      onChange?.();
    } catch (error) {
      setSnackbar(true, '保存に失敗しました', 'error');
      console.error(error);
    } finally {
      setUpdating(false);
    }
  }, [
    onChange,
    updateOrCreate,
    setSnackbar,
    setUpdating,
    getPayload,
    handleClickCancel,
  ]);

  const handleClickButton = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setPublishModal(true);
    },
    []
  );

  const modalProps = useMemo(() => {
    const setting: Omit<SimpleDialogProps, 'open'> = {
      submitLabel: '応募',
      title: '説明会応募',
    };
    if (product) {
      setting.title = `${product.organization.name}の編集`;
      setting.submitLabel = '保存';
    }
    return setting;
  }, [product]);

  useEffect(() => {
    if (product) {
      setProduct(product);
    }
    setFormValues({
      customFields: {
        parentId: parentProduct.id,
        schedules: parentProduct.customFields?.schedules?.map((schedule) => {
          return {
            date: schedule.date,
            occupations:
              product?.customFields?.schedules?.find(
                (s) => s.date === schedule.date
              )?.occupations || [],
          };
        }),
        subStatus: product?.customFields?.subStatus || SubStatus.APPLY,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublishModal, parentProduct, product]);

  return (
    <>
      <LoadingButton
        variant="outlined"
        color="tertiary"
        loading={isUpdating}
        onClick={handleClickButton}
        {...props}
      >
        {label}
      </LoadingButton>
      <SimpleDialog
        open={isPublishModal}
        cancelLabel="キャンセル"
        loading={isUpdating}
        disabled={!form.formState.isValid}
        fullWidth
        onClickSubmit={handleClickSubmit}
        onClickCancel={handleClickCancel}
        {...modalProps}
      >
        <FormProvider {...form}>
          <form>
            <Stack spacing={4}>
              <Typography>
                <span className="required-label" />
                は必須事項です
              </Typography>
              <Typography variant="body2" component="div">
                <div>■説明会名</div>
                <div>{parentProduct.name || ''}</div>
              </Typography>
              <Typography variant="body2" component="div">
                <div>■開催場所</div>
                <div>{getLocationLabel(parentProduct)}</div>
              </Typography>
              <Typography variant="body2" component="div">
                <div className="required-label">■参加する日時/職種</div>
                <Stack spacing={2}>
                  {parentProduct.customFields?.schedules?.map(
                    (schedule: Schedule, index: number) => (
                      <div key={index}>
                        <Typography variant="body2">
                          ・{dateFormat(schedule.date, 'M月d日(eee)')}
                        </Typography>
                        <Box ml={3}>
                          <FormCheckbox
                            name={`schedules.${index}.occupations`}
                            options={
                              schedule.occupations?.map((value: string) => {
                                return {
                                  label: ScheduleOccupation[value],
                                  value,
                                };
                              }) || []
                            }
                          />
                        </Box>
                      </div>
                    )
                  )}
                </Stack>
              </Typography>
            </Stack>
          </form>
        </FormProvider>
      </SimpleDialog>
    </>
  );
}
