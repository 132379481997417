import logo from '@app/assets/logo.svg';

export const ImageTypes = {
  NORMAL: 'normal',
  WIDE: 'wide',
} as const;
export type ImageTypesKey = (typeof ImageTypes)[keyof typeof ImageTypes];

interface LogoProps {
  height?: string;
  width?: string;
}

export function Logo({ height = '40', width = '140' }: LogoProps) {
  return <img src={logo} alt="Logo" width={width} height={height} />;
}
