import { atom, selector } from 'recoil';

import {
  getCategories,
  getLocationList,
  getLocationTree,
} from '@app/adapter/catalog-service';
import {
  Category,
  CategoryStatus,
  LocationType,
  ProductLocation,
} from '@app/types/catalog';

export const categoriesByActiveState = atom<Category[]>({
  default: [],
  key: 'catalog/categoriesByActiveState',
});
export const categoryByActiveSelector = selector<Category[]>({
  get: async ({ get }) => {
    const state = get(categoriesByActiveState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getCategories({
        filter: {
          status: CategoryStatus.ACTIVE,
        },
      });
      return value || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/categoryByActiveSelector',
  set: ({ set }, newData) => {
    set(categoriesByActiveState, newData);
  },
});

export const locationsTreeState = atom<ProductLocation[]>({
  default: [],
  key: 'catalog/locationsTreeState',
});
export const locationsTreeSelector = selector<ProductLocation[]>({
  get: async ({ get }) => {
    const state = get(locationsTreeState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getLocationTree({
        type: LocationType.REGION,
      });
      return value;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/locationsTreeSelector',
  set: ({ set }, newData) => {
    set(locationsTreeState, newData);
  },
});

export const locationsByPrefectureState = atom<ProductLocation[]>({
  default: [],
  key: 'catalog/locationsByPrefectureState',
});
export const locationsByPrefectureSelector = selector<ProductLocation[]>({
  get: async ({ get }) => {
    const state = get(locationsByPrefectureState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getLocationList({
        type: LocationType.PREFECTURE,
      });
      return value;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/locationsByPrefectureSelector',
  set: ({ set }, newData) => {
    set(locationsByPrefectureState, newData);
  },
});
