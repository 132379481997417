import { Box, FormLabel, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { ReactElement, useMemo } from 'react';

import { OrientationSubStatusButton } from '@app/administrator/components/Shared/Button/OrientationSubStatusButton';
import { useOrientationProduct } from '@app/administrator/hooks/useOrientationProduct';
import { OrientationApplyButton } from '@app/components/Catalog/OrientationApplyButton';
import { FormTextField } from '@app/components/Shared/Inputs/FormTextField';
import { ListTable } from '@app/components/Shared/ListTable';
import {
  Product,
  ProductSchedule as Schedule,
  ProductSubStatus as SubStatus,
} from '@app/types/catalog';
import { getOccupationsLabel } from '@app/utils/catalog';
import { ORIENTATION_OCCUPATION_COUNT_MAX as OCCUPATION_COUNT_MAX } from '@app/utils/constants';

export interface OrientationAppliedFormProps {
  disabled?: boolean;
  name?: string;
  parentProduct: Product;
  products: Product[];
  readonly?: boolean;
  schedule?: Schedule;
}

export function OrientationAppliedForm({
  disabled = false,
  name,
  parentProduct,
  products,
  readonly = false,
  schedule,
}: OrientationAppliedFormProps): ReactElement {
  const { childProducts, fetchChildProducts } = useOrientationProduct();

  const initialColumns: GridColDef[] = [
    {
      align: 'center',
      field: 'name',
      flex: 1,
      headerAlign: 'center',
      headerName: '病院名',
      minWidth: 200,
      renderCell: ({ row }) => (
        <Box className="text-ellipsis">{row.organization?.name || ''}</Box>
      ),
      sortable: false,
    },
    {
      align: 'center',
      field: 'occupation',
      flex: 1,
      headerAlign: 'center',
      headerName: '説明職種(参加上限数)',
      minWidth: 200,
      renderCell: ({ row }) => (
        <Box className="ow-break-word" textAlign="center">
          {getOccupationsLabel(row.customFields?.schedules, ',')
            .split(',')
            .map((occupation) => `${occupation}(${OCCUPATION_COUNT_MAX})`)
            .join('\n')}
        </Box>
      ),
      sortable: false,
    },
    {
      align: 'center',
      field: 'cancel',
      headerAlign: 'center',
      headerName: '欠席',
      renderCell: ({ row }) => (
        <OrientationSubStatusButton
          subStatus={SubStatus.ABSENT}
          product={row}
          variant="outlined"
          color="error"
          disabled={disabled}
          onChange={() => fetchChildProducts(parentProduct.id)}
        />
      ),
      sortable: false,
      width: 126,
    },
    {
      align: 'center',
      field: 'edit',
      headerAlign: 'center',
      headerName: '編集',
      renderCell: ({ row }) => (
        <OrientationApplyButton
          variant="outlined"
          color="tertiary"
          label="編集する"
          parentProduct={parentProduct}
          product={row}
          onChange={() => fetchChildProducts(parentProduct.id)}
        />
      ),
      sortable: false,
      width: 126,
    },
  ];

  const rows = useMemo(() => {
    return products.map((product) => {
      const child = childProducts.find((child) => child.id === product.id);
      return { ...product, ...child };
    });
  }, [products, childProducts]);

  const columns = useMemo(() => {
    if (readonly) {
      return initialColumns.filter((c) =>
        ['name', 'occupation'].includes(c.field)
      );
    }
    return initialColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readonly]);

  const scheduleDate = useMemo(() => {
    const time = schedule?.times
      ?.map(({ start, end }) => `${start}~${end}`)
      .join('/');
    return `${schedule?.date} ${time}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readonly]);

  return (
    <Stack>
      {!readonly && (
        <FormLabel focused={false} className="required-label">
          参加病院
        </FormLabel>
      )}
      <Typography variant="body2">{scheduleDate}</Typography>
      <ListTable
        columns={columns}
        rows={rows}
        rowCount={rows.length}
        rowHeight={76}
        className="b-radius-top-less"
        hideFooter
      />
      {!readonly && (
        <FormTextField
          name={`${name}.note`}
          placeholder="補足事項を入力"
          rows={3}
          multiline
        />
      )}
    </Stack>
  );
}
