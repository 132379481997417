import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { required } from '@app/schemas/base';
import {
  ProductFreeContent,
  ProductImage,
  ProductOrientationTypeKey,
  ProductPublication,
  ProductScheduleOccupationKey,
  ProductStatus,
  ProductStatusKey,
  ProductSubStatus,
  ProductSubStatusKey,
} from '@app/types/catalog';

/**
 * 説明会フォーム
 */
interface OrientationEditFormData {
  entryFee: string;
  freeContents: ProductFreeContent[];
  mainImages: ProductImage[];
  name: string;
  orientationType?: ProductOrientationTypeKey;
  publication: ProductPublication;
  schedules: {
    date: string;
    note: string;
    occupations: ProductScheduleOccupationKey[];
    orgMaxCount: string;
    timeNote: string;
    times: {
      end: string[];
      start: string[];
    }[];
  }[];
  subStatus: ProductSubStatusKey;
  supplyConfirmClosingDate: string;
  supplyRecruitClosingDate: string;
  survey: string;
  targetPersonNote: string;
  targetPersons: string[];
}
const OrientationEditFormDefaultValues: OrientationEditFormData = {
  entryFee: '',
  freeContents: [],
  mainImages: [],
  name: '',
  publication: {
    since: undefined,
    status: ProductStatus.DRAFT,
    until: undefined,
  },
  schedules: [
    {
      date: '',
      note: '',
      occupations: [],
      orgMaxCount: '',
      timeNote: '',
      times: [
        {
          end: ['', ''],
          start: ['', ''],
        },
      ],
    },
  ],
  subStatus: ProductSubStatus.DRAFT,
  supplyConfirmClosingDate: '',
  supplyRecruitClosingDate: '',
  survey: '',
  targetPersonNote: '',
  targetPersons: [],
};
const OrientationEditFormResolver = z.object({
  entryFee: required(),
  freeContents: z.array(
    z.object({
      title: z.string(),
      value: z.string(),
    })
  ),
  mainImages: z
    .array(
      z.object({
        url: z.string(),
      })
    )
    .min(1, '選択してください'),
  name: required(),
  publication: z.object({
    since: z.string().optional(),
    status: z.string(),
    until: z.string().optional(),
  }),
  schedules: z
    .array(
      z.object({
        date: required(undefined, true),
        occupations: z.array(z.string()).min(1, '選択してください'),
        orgMaxCount: required(),
        timeNote: z.string(),
        times: z.array(
          z.object({
            end: z.array(required(undefined, true)),
            start: z.array(required(undefined, true)),
          })
        ),
      })
    )
    .min(1),
  subStatus: z.string(),
  supplyConfirmClosingDate: z.string(),
  supplyRecruitClosingDate: required(undefined, true),
  survey: required(),
  targetPersonNote: z.string(),
  targetPersons: z.array(z.string()).min(1, '選択してください'),
});

/**
 * オンライン説明会 登録／編集フォーム
 */
export interface OnlineOrientationEditFormData
  extends Omit<OrientationEditFormData, 'schedules'> {
  schedules: (OrientationEditFormData['schedules'][number] & {
    zoom: string;
  })[];
}
export const OnlineOrientationEditFormDefaultValues: OnlineOrientationEditFormData =
  {
    ...OrientationEditFormDefaultValues,
    schedules: OrientationEditFormDefaultValues.schedules.map((schedule) => {
      return { ...schedule, zoom: '' };
    }),
  };
export const OnlineOrientationEditForm = {
  defaultValues: OnlineOrientationEditFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    OrientationEditFormResolver.extend({
      schedules: z.array(
        OrientationEditFormResolver.shape.schedules.element.extend({
          zoom: required(),
        })
      ),
    })
      .refine(
        (data) =>
          !(
            data.publication.status === ProductStatus.ACTIVE &&
            !data.publication.until
          ),
        {
          message: '選択してください',
          path: ['publication.until'],
        }
      )
      .refine(
        (data) =>
          !(
            data.subStatus === ProductSubStatus.CONFIRM &&
            !data.supplyConfirmClosingDate
          ),
        {
          message: '入力してください',
          path: ['supplyConfirmClosingDate'],
        }
      )
  ),
} as const;

/**
 * 現地説明会 登録／編集フォーム
 */
export interface OfflineOrientationEditFormData
  extends OrientationEditFormData {
  access: string;
  addressLine1: string;
}
export const OfflineOrientationEditFormDefaultValues: OfflineOrientationEditFormData =
  {
    ...OrientationEditFormDefaultValues,
    access: '',
    addressLine1: '',
  };
export const OfflineOrientationEditForm = {
  defaultValues: OnlineOrientationEditFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    OrientationEditFormResolver.extend({
      access: z.string(),
      addressLine1: z.string(),
    })
      .refine(
        (data) =>
          !(
            data.publication.status === ProductStatus.ACTIVE &&
            !data.publication.until
          ),
        {
          message: '選択してください',
          path: ['publication.until'],
        }
      )
      .refine(
        (data) =>
          !(
            data.subStatus === ProductSubStatus.CONFIRM &&
            !data.supplyConfirmClosingDate
          ),
        {
          message: '入力してください',
          path: ['supplyConfirmClosingDate'],
        }
      )
  ),
} as const;

/**
 * 説明会のステータス更新フォーム
 */
export interface OrientationStatusEditFormData {
  publishUntil: string;
  status: ProductStatusKey;
  subStatus: ProductSubStatusKey;
  supplyConfirmClosingDate: string;
}
export const OrientationStatusEditFormDefaultValues: OrientationStatusEditFormData =
  {
    publishUntil: '',
    status: ProductStatus.DRAFT,
    subStatus: ProductSubStatus.DRAFT,
    supplyConfirmClosingDate: '',
  };
export const OrientationStatusEditForm = {
  defaultValues: OrientationStatusEditFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z
      .object({
        publishUntil: z.string().optional(),
        status: z.string().optional(),
        subStatus: z.string().optional(),
        supplyConfirmClosingDate: z.string().optional(),
      })
      .refine(
        (data) =>
          !(
            data.subStatus === ProductSubStatus.CONFIRM &&
            !data.supplyConfirmClosingDate
          ),
        {
          message: '入力してください',
          path: ['supplyConfirmClosingDate'],
        }
      )
      .refine(
        (data) => !(data.status === ProductStatus.ACTIVE && !data.publishUntil),
        {
          message: '入力してください',
          path: ['publishUntil'],
        }
      )
  ),
} as const;

/**
 * 説明会応募フォーム
 */
export interface OrientationApplyFormData {
  orientationType?: ProductOrientationTypeKey;
  parentId: string | undefined;
  publication: ProductPublication;
  schedules: {
    date: string;
    occupations: ProductScheduleOccupationKey[];
  }[];
  subStatus: ProductSubStatusKey;
}
export const OrientationApplyFormDefaultValues: OrientationApplyFormData = {
  parentId: undefined,
  publication: {
    since: undefined,
    status: ProductStatus.DRAFT,
    until: undefined,
  },
  schedules: [
    {
      date: '',
      occupations: [],
    },
  ],
  subStatus: ProductSubStatus.APPLY,
};
export const OrientationApplyForm = {
  defaultValues: OrientationApplyFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      parentId: z.string().optional(),
      publication: z.object({
        since: z.string().optional(),
        status: z.string(),
        until: z.string().optional(),
      }),
      schedules: z.array(
        z.object({
          date: z.string(),
          occupations: z.array(z.string()).min(1, '選択してください'),
        })
      ),
      subStatus: z.string(),
    })
  ),
} as const;

/**
 * Product search form for admin
 */
export interface ProductSearchFormAdminData {
  sort: string;
  status: string;
}
export const ProductSearchFormAdminDefaultValues: ProductSearchFormAdminData = {
  sort: 'createdAt desc',
  status: '',
};
export const ProductSearchFormAdmin = {
  defaultValues: ProductSearchFormAdminDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      sort: z.string().optional(),
      status: z.string().optional(),
    })
  ),
} as const;

/**
 * Product search form
 */
export interface ProductSearchFormData {
  occupation: string;
  sort: string;
}
export const ProductSearchFormDefaultValues: ProductSearchFormData = {
  occupation: '',
  sort: 'createdAt desc',
};
export const ProductSearchForm = {
  defaultValues: ProductSearchFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      occupation: z.string().optional(),
      sort: z.string().optional(),
    })
  ),
} as const;
