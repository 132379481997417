import { LoadingButton } from '@mui/lab';
import {
  Box,
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { setRequestInterceptor } from '@app/adapter/axios';
import {
  getOrganizationRoleByUserId,
  getOrganizationsByUserId,
} from '@app/adapter/organization-service';
import { getUser } from '@app/adapter/user-service';
import { Link } from '@app/components/Shared/Link';
import {
  loggedInUserState,
  useClearAuthStateAndStorage,
  userAuthInfoSelector,
} from '@app/domain/app';
import { handleLogin } from '@app/domain/network-actions';
import { organization } from '@app/domain/organization';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { LoginForm, LoginFormData } from '@app/schemas/user';
import { storeAccessToken, storeUserId } from '@app/utils/auth';
import { isError } from '@app/utils/error';
import { isVendorUser } from '@app/utils/user';

export function Login(): ReactElement {
  const navigate = useNavigate();
  const setOrganization = useSetRecoilState(organization);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const setUserAuthInfo = useSetRecoilState(userAuthInfoSelector);
  const clearAuthStateAndStorage = useClearAuthStateAndStorage();
  const setSnackbar = useSetSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<LoginFormData>(LoginForm);

  const handleSubmitLogin = async (formData: LoginFormData) => {
    try {
      setIsLoading(true);

      const loggedIn = await handleLogin(formData.email, formData.password);
      setRequestInterceptor(loggedIn);

      const { data: user } = await getUser(loggedIn.id);
      // ユーザータイプのチェック
      if (!isVendorUser(user.typeId)) {
        throw new Error('このユーザーはこちらからログインできません');
      }

      const {
        data: { value: orgs },
      } = await getOrganizationsByUserId(user.id, {
        params: { $top: 1 },
      });
      // 組織への所属チェック
      if (!orgs.length) {
        throw new Error('このユーザーは所属しておりません');
      }

      const { data: role } = await getOrganizationRoleByUserId(
        orgs[0].id,
        user.id
      );

      // ログイン情報をセット
      setOrganization(orgs[0]);
      setLoggedInUser({ ...user, role: role.role });
      setUserAuthInfo(loggedIn);
      storeAccessToken(loggedIn.accessToken);
      storeUserId(loggedIn.id);

      navigate('/orientation-products');
    } catch (error) {
      clearAuthStateAndStorage();
      let errorMessage = 'ログインに失敗しました';
      if (isError(error)) {
        console.error(error.message);
        if (error.message === 'email or password is wrong') {
          errorMessage = 'メールアドレスまたはパスワードが間違っています';
        }
      }
      setSnackbar(true, errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitLogin)}>
      <Stack spacing={4}>
        <Typography variant="h5" textAlign="center">
          ログイン
        </Typography>
        <Stack spacing={3}>
          <FormControl fullWidth>
            <FormLabel focused={false}>メールアドレス</FormLabel>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="メールアドレスを入力"
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel focused={false}>パスワード</FormLabel>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="password"
                  error={!!error}
                  helperText={error?.message}
                  placeholder="パスワードを入力"
                />
              )}
            />
          </FormControl>
          <Box textAlign="center">
            <Link to="/password/reset">パスワードを忘れた方はこちら</Link>
          </Box>
        </Stack>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          loading={isLoading}
          disabled={!isValid}
          fullWidth
        >
          ログイン
        </LoadingButton>
      </Stack>
    </form>
  );
}
