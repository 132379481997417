import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { checkToken } from '@app/adapter/auth-service';
import {
  setRequestInterceptor,
  setResponseInterceptor,
} from '@app/adapter/axios';
import {
  getOrganizationsByUserId,
  getOrganizationRoleByUserId,
} from '@app/adapter/organization-service';
import { getUser } from '@app/adapter/user-service';
import { LoadingSpinner } from '@app/components/Shared/LoadingSpinner';
import {
  loggedInUserState,
  isValidUserAuthInfoState,
  userAuthInfoSelector,
  useClearAuthStateAndStorage,
} from '@app/domain/app';
import { generateFingerPrint } from '@app/domain/fingerprint';
import { organization } from '@app/domain/organization';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { getStoredAccessToken, getStoredUserId } from '@app/utils/auth';
import { isError } from '@app/utils/error';
import { ERROR_MESSAGE } from '@app/utils/message';
import { isVendorUser } from '@app/utils/user';

export function OrganizationUserRoute({
  children,
}: {
  children?: React.ReactNode;
}): ReactElement {
  const navigate = useNavigate();
  const [loadable, setLoadable] = useState<'loading' | 'error' | 'hasValue'>(
    'loading'
  );
  const isValidUserAuthInfo = useRecoilValue(isValidUserAuthInfoState);
  const setUserAuthInfo = useSetRecoilState(userAuthInfoSelector);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const setOrganization = useSetRecoilState(organization);
  const clearAuthStateAndStorage = useClearAuthStateAndStorage();
  const setSnackbar = useSetSnackbar();

  const validateToken = useCallback(async () => {
    try {
      const accessToken = getStoredAccessToken();
      if (!accessToken) {
        return false;
      }

      const fingerprint = await generateFingerPrint();
      const { data: checkTokenData } = await checkToken(
        accessToken,
        fingerprint
      );
      const userId = checkTokenData.userId;
      if (userId !== getStoredUserId()) {
        return false;
      }
      setRequestInterceptor({ accessToken, fingerprint });
      setUserAuthInfo({
        accessToken,
        fingerprint,
        id: userId,
      });

      const { data: orgData } = await getOrganizationsByUserId(userId, {
        params: { $skip: 0, $top: 1 },
      });
      if (orgData.value.length < 1) {
        return false;
      }
      setOrganization(orgData.value[0]);

      const { data: userData } = await getUser(userId);
      if (!isVendorUser(userData.typeId)) {
        throw new Error(ERROR_MESSAGE.INVALID_USER_TYPE);
      }
      const { data: roleData } = await getOrganizationRoleByUserId(
        orgData.value[0].id,
        userId
      );
      setLoggedInUser({ ...userData, role: roleData.role });

      return true;
    } catch (error) {
      if (isError(error)) {
        console.error(error.message);
      }
      return false;
    }
  }, [setUserAuthInfo, setLoggedInUser, setOrganization]);

  useEffect(() => {
    if (loadable === 'hasValue') return;
    if (loadable === 'error') {
      clearAuthStateAndStorage();
      navigate('/login');
      return;
    }

    const execute = async () => {
      // Step 1: Check the user's auth information.
      if (!isValidUserAuthInfo) {
        setLoadable('error');
      }

      const isValid = await validateToken();
      if (isValid) {
        setResponseInterceptor(() => {
          // on unauthorized
          clearAuthStateAndStorage();
          setTimeout(() => setSnackbar(true, ERROR_MESSAGE.TOKEN_EXPIRED), 300); // 他のsnackbarと競合対策で遅延させる
          navigate('/login');
        });

        setLoadable('hasValue');
        return;
      }
      setLoadable('error');
      return;
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    execute();
  }, [
    loadable,
    isValidUserAuthInfo,
    validateToken,
    navigate,
    clearAuthStateAndStorage,
    setSnackbar,
  ]);

  return loadable === 'hasValue' ? (
    <>{children}</>
  ) : (
    <LoadingSpinner sx={{ height: '100vh' }} />
  );
}
