import {
  OrganizationBranch,
  OrganizationStatus,
  OrganizationStatusKey,
  Plan,
  PlanKey,
} from '@app/types/organization';

export function getStatusLabel(status?: OrganizationStatusKey): string {
  switch (status) {
    case OrganizationStatus.NORMAL:
      return '利用中';
    case OrganizationStatus.CANCELLED:
      return '利用停止';
    default:
      return '-';
  }
}

export const isStoppedOrganization = (status: string) => {
  return status === OrganizationStatus.CANCELLED;
};

export function getPlanLabel(value: PlanKey): string {
  switch (value) {
    case Plan.VHR_MEMBER:
      return 'VHR会員';
    case Plan.GENERAL_MEMBER:
      return '一般会員';
    default:
      return '-';
  }
}

/**
 * 複数分院の都道府県を結合したラベルを取得
 */
export function getBranchPrefecturesLabel(
  branches: OrganizationBranch[] | undefined,
  separator = '・'
): string {
  return (
    branches
      ?.map((branch) => branch.addressLine1)
      .filter((i) => !!i)
      .join(separator) || ''
  );
}
