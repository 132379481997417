import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import { IconButton, Stack } from '@mui/material';
import { ReactElement, Fragment } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { AdditionalButton } from '@app/components/Shared/Button/AdditionalButton';
import { FormCheckbox } from '@app/components/Shared/Inputs/FormCheckbox';
import { FormDateField } from '@app/components/Shared/Inputs/FormDateField';
import { FormNumericField } from '@app/components/Shared/Inputs/FormNumericField';
import { FormTextField } from '@app/components/Shared/Inputs/FormTextField';
import { FormTimesSelect } from '@app/components/Shared/Inputs/FormTimesSelect';
import { OnlineOrientationEditFormDefaultValues as DefaultValue } from '@app/schemas/catalog';
import {
  ProductScheduleOccupation as Occupation,
  ProductOrientationType as OrientationType,
  ProductOrientationTypeKey as OrientationTypeKey,
} from '@app/types/catalog';

export const occupationOptions = Object.entries(Occupation).map(
  ([value, label]) => ({
    label,
    value,
  })
);

export interface OrientationScheduleFormProps {
  disabled?: boolean;
  orientationType: OrientationTypeKey;
}

export function OrientationScheduleForm({
  disabled = false,
  orientationType,
}: OrientationScheduleFormProps): ReactElement {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'schedules',
  });

  return (
    <Stack spacing={2.5}>
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <Stack direction="row" spacing={1}>
            <FormDateField
              name={`schedules.${index}.date`}
              title={!index ? '開催日' : ''}
              disabled={disabled}
              required
            />
            {!!index && (
              <div style={{ paddingTop: '6px' }}>
                <IconButton
                  size="small"
                  disabled={disabled}
                  onClick={() => remove(index)}
                >
                  <BackspaceOutlinedIcon color="error" />
                </IconButton>
              </div>
            )}
          </Stack>
          <Stack spacing={2.5} pl={5}>
            <FormTimesSelect
              name={`schedules.${index}.times`}
              endName="end"
              title="開催時間"
              disabled={disabled}
              required
            />
            <FormTextField
              name={`schedules.${index}.timeNote`}
              placeholder={
                '例)\n※集合時間：19:30〜19:45\n※開催説明：19:45〜20:00'
              }
              rows={3}
              multiline
            />
            <FormNumericField
              name={`schedules.${index}.orgMaxCount`}
              title="参加病院枠数"
              placeholder="0"
              disabled={disabled}
              required
              sx={{ width: 160 }}
            />
            <FormCheckbox
              name={`schedules.${index}.occupations`}
              title="職種"
              options={occupationOptions}
              disabled={disabled}
              required
            />
            {orientationType === OrientationType.ONLINE && (
              <FormTextField
                name={`schedules.${index}.zoom`}
                title="zoomURL"
                placeholder="zoomのURLを入力してください。"
                required
              />
            )}
          </Stack>
        </Fragment>
      ))}
      <AdditionalButton
        label="開催日を追加する"
        disabled={disabled || fields.length >= 5}
        onClick={() => append(DefaultValue.schedules)}
      />
    </Stack>
  );
}
