import {
  CategoryNameKey,
  Product,
  ProductOrientationTypeKey,
  ProductVariant,
} from '@app/types/catalog';
import { Organization } from '@app/types/organization';
import { User, ReceiveGift } from '@app/types/user';

export interface Order {
  cancelReason: CancelReasonKey | null;
  canceledAt: string;
  cart?: string;
  checkout?: string;
  closedAt: string;
  createdAt: string;
  currency: string;
  customFields?: OrderCustomFields;
  customer: OrderCustomer;
  gender: string;
  id: string;
  lineItems: OrderLineItem[];
  name: string;
  organization: string | Organization;
  status: OrderStatusKey;
  subTotalPrice: number;
  totalPrice: number;
  totalTax: number;
  updatedAt: string;
}

export interface OrderCustomFields {
  benefitReceive?: OrderBenefitReceiveKey[];
  dates?: string[];
  inquiry?: string;
  note?: string;
  orientationGroupKey?: string;
  orientationType?: ProductOrientationTypeKey;
  productCategory?: CategoryNameKey;
  publishAddressFlag?: boolean;
  publishPhoneFlag?: boolean;
  reason?: string;
  referrerName?: string;
  transferStatus?: OrderTransferStatusKey;
}

export interface OrderLineItem {
  price: OrderLineItemPrice;
  product: string | Product;
  productName: string;
  quantity: number;
  sku: string;
  variant: string | ProductVariant;
  variantTitle: string;
}

export interface OrderLineItemPrice {
  amount: number;
  taxable: boolean;
}

export interface OrderCustomer {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  age: string;
  department: string;
  email: string;
  name: string;
  nameKana: string;
  phone: string;
  prefecture: string;
  preferredContactMethod: string;
  preferredTimeToContact: string;
  rate: string;
  user: string | User;
}

export interface OrderCustomForm {
  answer: string[];
  question: string;
}

export interface OrderUpdate {
  cancelReason?: CancelReasonKey | null;
  canceledAt?: string;
  customFields?: OrderCustomFields;
  status?: OrderStatusKey;
}

export interface OrderStats {
  count: number;
  organization: string;
  productId: string;
}

export interface OrderBadgeState {
  exhibition: {
    request: number;
    visitor: number;
  };
  property: {
    request: number;
    visitor: number;
  };
}

export const OrderStatus = {
  ACCEPTED: 'ACCEPTED',
  CANCELED: 'CANCELED',
  CLOSED: 'CLOSED',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
} as const;
export type OrderStatusKey = (typeof OrderStatus)[keyof typeof OrderStatus];

export const CancelReason = {
  CUSTOMER: 'customer',
  DECLINED: 'declined',
  INVENTORY: 'inventory',
  OTHER: 'other',
} as const;
export type CancelReasonKey = (typeof CancelReason)[keyof typeof CancelReason];

export const OrderBenefitReceive = ReceiveGift;
export type OrderBenefitReceiveKey =
  (typeof OrderBenefitReceive)[keyof typeof OrderBenefitReceive];

export const OrderTransferStatus = {
  COMPLETED: 'completed',
  PENDING: 'pending',
} as const;
export type OrderTransferStatusKey =
  (typeof OrderTransferStatus)[keyof typeof OrderTransferStatus];
