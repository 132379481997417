import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import {
  SimpleDialog,
  SimpleDialogProps,
} from '@app/components/Shared/Dialog/SimpleDialog';
import { FormRadioGroup } from '@app/components/Shared/Inputs/FormRadioGroup';
import { useOrder } from '@app/hooks/useOrder';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import {
  StatusEditForm as Form,
  StatusEditFormData as FormData,
} from '@app/schemas/order';
import { Order } from '@app/types/order';
import { Organization } from '@app/types/organization';
import { User } from '@app/types/user';
import { isError } from '@app/utils/error';
import { statusOptions } from '@app/utils/order';

export interface StatusEditDialogProps extends SimpleDialogProps {
  order?: Order;
  setOpen: (value: boolean) => void;
}

export function StatusEditDialog({
  onClickCancel,
  onClickSubmit,
  order,
  setOpen,
  ...props
}: StatusEditDialogProps): ReactElement {
  const setSnackbar = useSetSnackbar();
  const { isUpdating, update } = useOrder();

  const form = useForm<FormData>(Form);
  const {
    formState: { isValid },
    getValues,
    reset,
    setValue,
  } = form;

  useEffect(() => {
    if (order) {
      setValue('status', order.status);
    }
  }, [order, setValue]);

  const handleClickCancel = useCallback(() => {
    setOpen(false);
    reset();
    onClickCancel?.();
  }, [setOpen, reset, onClickCancel]);

  const handleClickSubmit = useCallback(async () => {
    try {
      const organizationId =
        typeof order?.organization === 'object'
          ? (order.organization as Organization).id
          : order?.organization;
      if (!organizationId || !order) {
        throw new Error('問題が発生しました');
      }

      await update(organizationId, order.id, {
        status: getValues('status'),
      });
      setSnackbar(true, '更新しました', 'success');
      onClickSubmit?.();
      handleClickCancel();
    } catch (e) {
      setSnackbar(true, '更新に失敗しました', 'error');
      if (isError(e)) {
        console.error(e.message);
      }
    }
  }, [update, order, getValues, onClickSubmit, setSnackbar, handleClickCancel]);

  const userName = useMemo(() => {
    const userInfo = order?.customer.user as User;
    return `${userInfo?.customFields?.familyName || ''}${
      userInfo?.customFields?.firstName || ''
    }`;
  }, [order]);

  return (
    <SimpleDialog
      title={`${userName}さんの参加状況`}
      submitLabel="更新"
      cancelLabel="キャンセル"
      disabled={!isValid}
      loading={isUpdating}
      fullWidth
      onClickSubmit={handleClickSubmit}
      onClickCancel={handleClickCancel}
      {...props}
    >
      <FormProvider {...form}>
        <form>
          <FormRadioGroup
            name="status"
            options={statusOptions}
            title={`${userName}さんの参加状況を更新してください。`}
            row
          />
        </form>
      </FormProvider>
    </SimpleDialog>
  );
}
