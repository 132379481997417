import { useCallback, useEffect, useState } from 'react';

import {
  createProduct,
  deleteProduct,
  getAdminProducts,
  getProduct,
  updateProduct,
} from '@app/adapter/catalog-service';
import { Product, ProductCreate, ProductUpdate } from '@app/types/catalog';
import { isError } from '@app/utils/error';

export const useProduct = (orgId: string, id?: string) => {
  const [isLoading, setLoading] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  /**
   * Productを取得してセットする
   */
  const [product, setProduct] = useState<Product>();
  const fetchProduct = useCallback(
    async (id: string, adminFlg = false) => {
      try {
        setLoading(true);
        if (adminFlg) {
          const {
            data: { value },
          } = await getAdminProducts(orgId, {
            filter: { id },
            pageSize: 1,
          });
          setProduct(value[0]);
          return value[0];
        }
        const { data } = await getProduct(id, {
          expand: 'images',
        });
        setProduct(data);
        return data;
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [orgId]
  );

  useEffect(() => {
    if (id) {
      void fetchProduct(id);
    }
  }, [id, fetchProduct]);

  /**
   * 登録／更新APIにリクエストする
   */
  const updateOrCreate = async (payload: ProductCreate | ProductUpdate) => {
    try {
      setUpdating(true);
      if (product?.id) {
        await updateProduct(product.id, payload as ProductUpdate);
        return await fetchProduct(product.id);
      }
      const { data } = await createProduct(orgId, payload as ProductCreate);
      return await fetchProduct(data.id);
    } catch (error) {
      if (isError(error)) {
        console.error(error.message);
        throw new Error(error.message);
      }
    } finally {
      setUpdating(false);
    }
  };

  const removeProduct = useCallback(
    async (id?: string) => {
      try {
        const targetId = id || product?.id;
        if (!targetId) {
          throw new Error('指定IDがありません');
        }

        setUpdating(true);
        await deleteProduct(targetId);
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
          throw new Error(error.message);
        }
      } finally {
        setUpdating(false);
      }
    },
    [product]
  );

  return {
    fetchProduct,
    isLoading,
    isUpdating,
    product,
    removeProduct,
    setProduct,
    setUpdating,
    updateOrCreate,
  };
};
