import { Card, Stack } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { OrientationProductInfo } from '@app/administrator/components/Order/OrientationProductInfo';
import { UserInfo } from '@app/components/Order/UserInfo';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { isLoading } from '@app/domain/app';
import { organization } from '@app/domain/organization';
import { useOrder } from '@app/hooks/useOrder';
import { User } from '@app/types/user';

export function OrientationDetail() {
  const { groupKey } = useParams();
  const currentOrg = useRecoilValue(organization);
  const [, setLoadingState] = useRecoilState(isLoading);
  const { subOrders, fetchSubOrders } = useOrder();

  useEffect(() => {
    const execute = async () => {
      if (groupKey) {
        setLoadingState(true);
        await fetchSubOrders([groupKey], currentOrg?.id);
        setLoadingState(false);
      }
    };
    void execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupKey, currentOrg, fetchSubOrders]);

  const user = useMemo(() => {
    return subOrders[0]?.customer?.user as User;
  }, [subOrders]);

  return (
    <>
      <PageTitle title="説明会応募" />
      <Card>
        <Stack spacing={5} p={4}>
          <OrientationProductInfo subOrders={subOrders} />
          {!!user && (
            <UserInfo
              user={user}
              isHideAddress={
                subOrders[0]?.customFields?.publishAddressFlag || true
              }
              isHidePhone={subOrders[0]?.customFields?.publishPhoneFlag || true}
            />
          )}
        </Stack>
      </Card>
    </>
  );
}
