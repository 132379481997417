import { ReviewStats } from '@app/types/review';
import { User } from '@app/types/user';

export interface Organization {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  auditStatus: OrganizationAuditStatusKey;
  branchName?: string;
  certificateImageUri?: string;
  chart?: boolean;
  children?: Organization[];
  childrenCount: number;
  createdAt: string;
  customFields?: OrganizationCustomFields;
  description?: string;
  electronicChart?: boolean;
  electronicChartMaker?: string;
  equipment?: string[];
  equipmentOther?: string;
  iconImageUri?: string;
  id: string;
  logoImageUri?: string;
  name: string;
  numberOfProjects?: string;
  ownerId: string;
  parent?: Organization;
  parentId: string;
  phoneNumber?: string;
  pictureUrl?: string;
  postalCode?: string;
  reviewStats?: ReviewStats;
  size?: string;
  status: OrganizationStatusKey;
  typeId: OrganizationTypeKey;
  updatedAt: string;
  url?: string;
  users?: OrganizationUserRole[];
}

export interface OrganizationCustomFields {
  access?: string;
  addressLabel?: string;
  addressLine4?: string;
  adminFlag?: boolean;
  billingContact?: string;
  branches?: OrganizationBranch[];
  catchphrase?: string;
  contacts?: OrganizationContact[];
  facilityTypes?: FacilityTypeKey[];
  plan?: PlanKey;
  recruitTypeBeginner?: RecruitTypeKey[];
  recruitTypeExperience?: RecruitTypeKey[];
  remark?: string;
  scoutLimit?: number;
}

export interface OrganizationContact {
  email: string;
  note: string;
}

export type OrganizationCreate = Partial<Organization> & {
  iconImageData?: string;
};

export type OrganizationUpdate = Omit<OrganizationCreate, 'ownerId'>;

export interface OrganizationImage {
  id?: string;
  type?: string;
  url: string;
}

export interface OrganizationBranch {
  access?: string;
  addressLine1?: string;
  addressLine2?: string;
  name?: string;
  phoneNumber?: string;
}

export const OrganizationType = {
  COMPANY: '010',
  PERSONAL: '011',
} as const;
export type OrganizationTypeKey =
  (typeof OrganizationType)[keyof typeof OrganizationType];

export const OrganizationStatus = {
  CANCELLED: 'cancelled',
  FORBIDDEN: 'forbidden',
  NORMAL: 'normal',
  RESTRICTED: 'restricted',
} as const;
export type OrganizationStatusKey =
  (typeof OrganizationStatus)[keyof typeof OrganizationStatus];

export const OrganizationAuditStatus = {
  APPROVED: 'approved',
  CHANGE_REQUESTED: 'change_requested',
  IN_REVIEW: 'in_review',
  REJECTED: 'rejected',
  WAITING_FOR_CHANGE_REVIEW: 'waiting_for_change_review',
  WAITING_FOR_REVIEW: 'waiting_for_review',
} as const;
export type OrganizationAuditStatusKey =
  (typeof OrganizationAuditStatus)[keyof typeof OrganizationAuditStatus];

export const OrganizationStructureType = {
  CHILD: 'child',
  PARENT: 'parent',
} as const;
export type OrganizationStructureTypeKey =
  (typeof OrganizationStructureType)[keyof typeof OrganizationStructureType];

export const Plan = {
  GENERAL_MEMBER: 'generalMember',
  VHR_MEMBER: 'vhrMember',
} as const;
export type PlanKey = (typeof Plan)[keyof typeof Plan];

export const FacilityType: {
  [key: string]: string;
} = {
  '01': '1次診療',
  '02': '1.5次診察',
  '03': '2次診察',
  '04': 'エキゾチック診療',
  '05': '専門診療',
  '06': '救急診療',
  '07': '24時間診療',
  '08': '大動物診療',
} as const;
export type FacilityTypeKey = (typeof FacilityType)[keyof typeof FacilityType];

export const RecruitType: {
  [key: string]: string;
} = {
  '01': '獣医師',
  '02': '愛玩動物看護師',
  '03': '動物看護助手',
  '04': '受付',
  '05': 'トリマー',
  '06': 'その他',
} as const;
export type RecruitTypeKey = (typeof RecruitType)[keyof typeof RecruitType];

/**
 * Organization user role
 */
export const UserRole = {
  ADMIN: 'admin',
  GUEST: 'guest',
  MEMBER: 'member',
  OWNER: 'owner',
} as const;
export type UserRoleKey = (typeof UserRole)[keyof typeof UserRole];

export interface OrganizationUserRole {
  id: string;
  role: UserRoleKey;
}
export interface OrganizationUser extends User {
  role: UserRoleKey;
}

/**
 * Attachment
 */
export interface Attachment {
  id: string;
  isPublic: boolean;
  ownerId: string;
  ownerType: AttachmentOwnerTypeKey;
  type: string;
  url: string;
}

export interface AttachmentCreate {
  objectId: string;
  ownerId?: string;
  type: string;
}

export const AttachmentOwnerType = {
  ORGANIZATION: 'organization',
} as const;
export type AttachmentOwnerTypeKey =
  (typeof AttachmentOwnerType)[keyof typeof AttachmentOwnerType];

export const AddressLabelType: {
  [key: string]: string;
} = {
  '01': '本社',
  '02': '本院',
  '03': '人事部',
  '04': 'その他',
} as const;
export type AddressLabelTypeKey =
  (typeof AddressLabelType)[keyof typeof AddressLabelType];
