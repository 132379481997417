import { Product } from '@app/types/catalog';
import {
  Order,
  OrderBenefitReceive,
  OrderBenefitReceiveKey,
  OrderStatus,
  OrderStatusKey,
  OrderTransferStatus,
  OrderTransferStatusKey,
} from '@app/types/order';
import { dateFormat } from '@app/utils/format';

export const statusOptions = [
  {
    label: getOrderStatusLabel(OrderStatus.PENDING),
    value: OrderStatus.PENDING,
  },
  {
    label: getOrderStatusLabel(OrderStatus.ACCEPTED),
    value: OrderStatus.ACCEPTED,
  },
  {
    label: getOrderStatusLabel(OrderStatus.CLOSED),
    value: OrderStatus.CLOSED,
  },
  {
    label: getOrderStatusLabel(OrderStatus.CANCELED),
    value: OrderStatus.CANCELED,
  },
];

/**
 * ステータスのラベルを取得
 */
export function getOrderStatusLabel(value: OrderStatusKey): string {
  switch (value) {
    case OrderStatus.PENDING:
      return '予定';
    case OrderStatus.ACCEPTED:
      return '済み';
    case OrderStatus.CLOSED:
      return '拒否';
    case OrderStatus.CANCELED:
      return '辞退';
  }
  return '';
}

/**
 * 参加賞の受取方法のラベルを取得
 */
export function getBenefitReceiveLabel(value: OrderBenefitReceiveKey): string {
  switch (value) {
    case OrderBenefitReceive.CASH:
      return '現金';
    case OrderBenefitReceive.AMAZON_GIFT:
      return 'amazonギフト券';
    case OrderBenefitReceive.QUO_CARD:
      return 'QUOカード';
    case OrderBenefitReceive.UNNECESSARY:
      return '受け取らない';
  }
  return '';
}

/**
 * 参加賞の受取方法を結合したラベルを取得
 */
export function getBenefitReceivesLabel(
  values: OrderBenefitReceiveKey[] | undefined,
  separator = '・'
): string {
  return (
    values
      ?.map((value) => getBenefitReceiveLabel(value))
      .filter((i) => !!i)
      .join(separator) || ''
  );
}

/**
 * 振込ステータスのラベルを取得
 */
export function getTransferStatusLabel(value: OrderTransferStatusKey): string {
  switch (value) {
    case OrderTransferStatus.PENDING:
      return '未対応';
    case OrderTransferStatus.COMPLETED:
      return '振込済';
  }
  return '未対応';
}

/**
 * 応募した参加日時のラベルを取得
 */
export function getScheduleDateLabel(order: Order, withTime = false): string {
  const startDate = order.customFields?.dates?.[0] || '';
  if (!withTime) {
    return dateFormat(startDate, 'M月d日');
  }

  const product = order.lineItems[0].product as Product;

  let endTime;
  product.customFields?.schedules?.forEach((schedule) =>
    schedule.times?.forEach((time) => {
      if (`${schedule.date} ${time.start}` === startDate) {
        endTime = time.end;
      }
    })
  );
  return (
    dateFormat(startDate, 'M月d日 HH:mm') + (endTime ? ` ~ ${endTime}` : '')
  );
}
