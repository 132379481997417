import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

const palette = {
  error: {
    light: '#fff2f2',
    main: '#dd6262',
  },
  primary: {
    main: '#ca6172',
  },
  secondary: {
    main: '#dbdfe5',
  },
  tertiary: {
    contrastText: '#fff',
    main: '#19a7ce',
  },
  text: {
    primary: '#2f2f2f',
  },
};

const customPalette = {
  black: '#333',
  blue1: '#1eb1b4',
  blue2: '#19a7ce',
  gray1: '#b0b7c3',
  gray2: '#828a9b',
  gray3: '#f7f7f7',
  gray4: '#b7bcc6',
  gray5: '#e0e0e0',
  gray6: '#dbdfe5',
  gray7: '#5a6170',
  gray8: '#7a7a7a',
  gray9: '#ccc',
  green1: '#159e4d',
  green2: '#e8f5ed',
  navy1: '#0a2252',
  white: '#fff',
  yellow1: '#f4d116',
  yellow2: '#e48B67',
};

declare module '@mui/material/styles' {
  interface Theme {
    customPalette: {
      black: string;
      blue1: string;
      blue2: string;
      gray1: string;
      gray2: string;
      gray3: string;
      gray4: string;
      gray5: string;
      gray6: string;
      gray7: string;
      gray8: string;
      gray9: string;
      green1: string;
      green2: string;
      navy1: string;
      white: string;
      yellow1: string;
      yellow2: string;
    };
  }
  interface ThemeOptions {
    customPalette?: {
      black: string;
      blue1: string;
      blue2: string;
      gray1: string;
      gray2: string;
      gray3: string;
      gray4: string;
      gray5: string;
      gray6: string;
      gray7: string;
      gray8: string;
      gray9: string;
      green1: string;
      green2: string;
      navy1: string;
      white?: string;
      yellow1: string;
      yellow2: string;
    };
  }
  interface Palette {
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
  }
  interface TypographyVariants {
    body3: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

export const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        action: {
          padding: '0 0 0 16px',
        },
        message: {
          padding: '10px 0',
        },
        root: {
          fontSize: '0.75rem',
          padding: '2px 12px',
        },
        standardError: {
          backgroundColor: palette.error.light,
          color: palette.error.main,
        },
        standardInfo: {
          backgroundColor: customPalette.gray3,
        },
        standardSuccess: {
          backgroundColor: customPalette.green2,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          '&.bottom-area': {
            borderTop: `1px solid ${customPalette.gray5}`,
            bottom: 0,
            top: 'auto',
          },
          '&.header-area': {
            borderBottom: `1px solid ${customPalette.gray5}`,
          },
          backgroundColor: 'white',
          boxShadow: 'none',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: customPalette.gray5,
          color: '#8890A0',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          '&.MuiButton-tertiary': {
            '&:hover': {
              //backgroundColor: 'rgba(0, 191, 165, 0.08)',
              borderColor: palette.tertiary.main,
            },
            borderColor: palette.tertiary.main,
            color: palette.tertiary.main,
          },
          backgroundColor: 'white',
        },
        outlinedSecondary: {
          borderColor: palette.secondary.main,
          color: customPalette.black,
        },
        root: {
          '&.Mui-disabled': {
            background: customPalette.gray5,
            color: 'white',
          },
          boxShadow: 'none !important',
          fontSize: 16,
          fontWeight: 400,
        },
        sizeMedium: {
          fontSize: '0.875rem',
          padding: '7px 18px',
        },
        sizeSmall: {
          fontSize: '0.75rem',
          minWidth: 'auto',
          padding: '8px',
        },
        textSecondary: {
          color: customPalette.black,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '.MuiSvgIcon-root': {
            '&.MuiSvgIcon-fontSizeSmall': {
              fontSize: '1.334rem',
            },
            fontSize: '1.5rem',
          },
          color: customPalette.gray4,
          padding: '4px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          background:
            'linear-gradient(90deg, rgba(0, 224, 213, 0.1) 8.14%, rgba(59, 142, 192, 0.1) 91.86%)',
          color: customPalette.blue1,
        },
        root: {
          '.MuiChip-icon': {
            color: 'inherit',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          backgroundColor: 'white',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          ':focus-within': { outline: 'none' },
          fontSize: '0.875rem',
        },
        columnHeader: {
          ':focus-within': { outline: 'none' },
          fontSize: '0.75rem',
        },
        columnHeaderTitle: {
          fontWeight: 400,
        },
        columnHeaders: {
          backgroundColor: customPalette.gray5,
        },
        root: {
          border: 'none',
        },
        row: {
          '&.Mui-selected': {
            ':hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: customPalette.gray6,
          borderWidth: 0.5,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: customPalette.gray8,
          fontSize: '0.75rem',
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: customPalette.black,
          fontSize: '0.875rem',
          marginBottom: '8px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorSecondary: {
          color: customPalette.black,
        },
        root: {
          '&.image-remove': {
            '&:hover': {
              backgroundColor: 'white',
            },
            backgroundColor: 'white',
            color: 'black',
            padding: 0,
            position: 'absolute',
            right: '-0.625rem',
            top: '-0.625rem',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          ':not(textarea)': {
            padding: '12px',
          },
        },
        root: {
          '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
          '&.MuiInputBase-multiline': {
            padding: '12px',
          },
          fontSize: '1rem',
          lineHeight: 'inherit',
        },
        sizeSmall: {
          height: '36px',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            ':hover': {
              '.MuiListItemIcon-root': {
                color: 'white',
              },
              backgroundColor: palette.primary.main,
              color: 'white',
            },
            backgroundColor: palette.primary.main,
            color: 'white',
          },
          ':hover': {
            '.MuiListItemIcon-root': {
              color: 'white',
            },
            backgroundColor: palette.primary.main,
            color: 'white',
          },
        },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          '.MuiIconButton-root': {
            color: 'white',
          },
          backdropFilter: 'blur(4px)',
          backgroundColor: 'rgba(51,51,51,0.4)',
          borderRadius: '16px',
          color: 'white',
          cursor: 'default',
          display: 'inline-flex',
          padding: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          ':not(textarea)': {
            padding: '12px',
          },
          fontSize: '1rem',
        },
        notchedOutline: {
          borderColor: customPalette.gray9,
        },
        root: {
          '&.border-none > .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          border: `1px solid ${customPalette.gray5}`,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '.MuiSvgIcon-root': {
            height: '1rem',
            width: '1rem',
          },
          padding: '8px',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownIcon,
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: 700,
          },
          color: customPalette.gray4,
          fontSize: '0.75rem',
          fontWeight: 400,
          minHeight: '32px',
          padding: '8px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        actions: {
          '.MuiIconButton-root:not(:disabled)': {
            color: customPalette.gray2,
          },
        },
        displayedRows: {
          fontSize: '1rem',
        },
        select: {
          ':focus': {
            borderRadius: '4px',
          },
          border: `1px solid ${customPalette.gray1}`,
          borderRadius: '4px',
          fontSize: '1rem',
          paddingBottom: '4px',
          paddingTop: '4px',
          textAlignLast: 'left',
          width: '66px',
        },
        selectIcon: {
          color: customPalette.gray2,
          fontSize: '1.5rem',
          marginRight: '8px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '32px',
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  customPalette,
  palette,
  typography: {
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    body3: {
      fontSize: '0.75rem',
    },
    fontFamily: [
      '"Segoe UI"',
      '"Meiryo"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Hiragino Sans"',
      '"Roboto"',
      '"Noto Sans JP"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 16,
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.375rem',
      fontWeight: 700,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
  },
});
