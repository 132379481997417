import { MessageStatuses } from '@app/types/notification';

export const {
  REACT_APP_ADMIN_ORGANIZATION_ID: ADMIN_ORGANIZATION_ID,
  REACT_APP_ADMINISTRATOR_BASE_PATH: ADMINISTRATOR_BASE_PATH,
  REACT_APP_DEMAND_APP: DEMAND_APP,
} = process.env;

export const STORAGE_KEYS = {
  /*
    localStorage, sessionStorage を使う場合はここに key を追加
    重複しないように一元管理
  */
  ACCESS_TOKEN: 'accessToken',
  FINGERPRINT: 'fingerprint',
  REFRESH_TOKEN: 'refreshToken',
  USER_ID: 'userId',
};

export const ORGANIZATION_STRUCTURE_TYPE = {
  CHILDREN: 'children',
  PARENT: 'parent',
};

export const USER_STATUS_LABELS = {
  LOCKED: '利用停止',
  NORMAL: '利用中',
};

export const MESSAGE_STATUS_MAPPING_LABELS = {
  CLOSE: '非公開',
  DRAFT: '非公開',
  PUBLISHED: '公開',
  WAITING: '非公開',
};

export const MESSAGE_TARGET_MAPPING_LABELS = {
  common: '共通 ',
  demand: 'ユーザー向け',
  supply: '企業向け',
};

export const SEARCH_STATUS_OPTIONS = [
  { label: USER_STATUS_LABELS.NORMAL, value: 'active' },
  { label: USER_STATUS_LABELS.LOCKED, value: 'stop' },
] as const;

export const USER_ROLES_LABELS = {
  MEMBER: 'メンバー',
  OWNER: '管理者',
};

export const NOTIFICATION_SEARCH_STATUS_OPTIONS = [
  {
    label: '公開',
    value: MessageStatuses.PUBLISHED,
  },
  { label: '非公開', value: MessageStatuses.DRAFT },
];

export const SORT_OPTIONS = [
  { label: '新しい順', value: 'createdAt desc' },
  { label: '古い順', value: 'createdAt' },
];

export const ORIENTATION_OCCUPATION_COUNT_MAX = 10;
