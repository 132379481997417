import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { UserRole } from '@app/types/organization';
import { generatePasswordPattern } from '@app/utils/pattern';

export const passwordSchema = z
  .string()
  .min(8, 'パスワードは記号を除く、英数字含む8文字以上を入力してください')
  .max(24, '24文字以下のパスワードを入力してください')
  .regex(generatePasswordPattern(), {
    message: '小文字英字と数字を組み合わせてください',
  });

/**
 * login form
 */
export interface LoginFormData {
  email: string;
  password: string;
}
export const LoginDefaultValues: LoginFormData = {
  email: '',
  password: '',
};
export const LoginFormSchema = z.object({
  email: z.string().email({ message: 'メールアドレスを入力してください' }),
  password: z.string().min(1, { message: 'パスワードを入力してください' }),
});
export const LoginForm = {
  defaultValues: LoginDefaultValues,
  mode: 'all',
  resolver: zodResolver(LoginFormSchema),
} as const;

/**
 * password reset email form
 */
export interface PasswordResetEmailFormData {
  email: string;
}
export const PasswordResetEmailDefaultValues: PasswordResetEmailFormData = {
  email: '',
};
export const PasswordResetEmailFormSchema = z.object({
  email: z.string().email({ message: 'メールアドレスを入力してください' }),
});
export const PasswordResetEmailForm = {
  defaultValues: PasswordResetEmailDefaultValues,
  mode: 'all',
  resolver: zodResolver(PasswordResetEmailFormSchema),
} as const;

/**
 * password reset new password form
 */
export interface PasswordResetFormData {
  password: string;
  passwordConfirm: string;
}
export const PasswordResetDefaultValues: PasswordResetFormData = {
  password: '',
  passwordConfirm: '',
};
export const PasswordResetFormSchema = z
  .object({
    password: passwordSchema,
    passwordConfirm: passwordSchema,
  })
  .refine(({ password, passwordConfirm }) => password === passwordConfirm, {
    message: 'パスワードが一致しません',
    path: ['passwordConfirm'],
  });
export const PasswordResetForm = {
  defaultValues: PasswordResetDefaultValues,
  mode: 'all',
  resolver: zodResolver(PasswordResetFormSchema),
} as const;

/**
 * User search form
 */
export interface UserSearchFormData {
  desiredJob: string;
  identificationStatus: string;
  organizationIds: string[];
  schoolName: string;
  sort: string;
  status: string;
}
export const UserSearchFormDefaultValues: UserSearchFormData = {
  desiredJob: '',
  identificationStatus: '',
  organizationIds: [],
  schoolName: '',
  sort: 'createdAt desc',
  status: '',
};
export const UserSearchFormSchema = z.object({
  desiredJob: z.string(),
  identificationStatus: z.string(),
  organizationIds: z.array(z.string().optional()),
  schoolName: z.string(),
  sort: z.string().optional(),
  status: z.string().optional(),
});
export const UserSearchForm = {
  defaultValues: UserSearchFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(UserSearchFormSchema),
} as const;

/**
 * User edit form
 */
export interface UserEditFormData {
  email: string;
  familyName: string;
  firstName: string;
  role: string;
}
export const UserEditFormDefaultValues: UserEditFormData = {
  email: '',
  familyName: '',
  firstName: '',
  role: UserRole.OWNER,
};
export const UserEditFormSchema = z.object({
  email: z.string().email({ message: 'メールアドレスを入力してください' }),
  familyName: z.string().min(1, { message: '姓を入力してください' }),
  firstName: z.string().min(1, { message: '名を入力してください' }),
  role: z.string().min(1, { message: '権限を選択してください' }),
});
export const UserEditForm = {
  defaultValues: UserEditFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(UserEditFormSchema),
} as const;

/**
 * User invite form
 */
export interface UserInviteFormData {
  users: { email: string; role: string }[];
}
export const UserInviteFormDefaultValues: UserInviteFormData = {
  users: Array(5).fill({ email: '', role: '' }),
};
export const UserInviteFormSchema = z.object({
  users: z
    .array(
      z.object({
        email: z
          .string()
          .optional()
          .refine((val) => !val || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val), {
            message: 'メールアドレスを入力してください',
          }),
        role: z.string().optional(),
      })
    )
    .length(5)
    .refine((users) => users.every((user) => !(user.email && !user.role)), {
      message: '権限を選択してください',
      path: ['role'],
    }),
});
export const UserInviteForm = {
  defaultValues: UserInviteFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(UserInviteFormSchema),
} as const;

/**
 * 本人確認書類関連の編集フォーム
 */
export interface IdentificationEditFormData {
  identificationNote: string;
}
export const IdentificationEditFormDefaultValues: IdentificationEditFormData = {
  identificationNote: '',
};
export const IdentificationEditForm = {
  defaultValues: IdentificationEditFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      identificationNote: z.string(),
    })
  ),
} as const;

/**
 * Scout input form
 */
export interface ScoutInputFormData {
  message: string;
}
export const ScoutInputFormDefaultValues: ScoutInputFormData = {
  message: '',
};
export const ScoutInputFormSchema = z.object({
  message: z.string().min(1, { message: 'メッセージを入力してください' }),
});
export const ScoutInputForm = {
  defaultValues: ScoutInputFormDefaultValues,
  resolver: zodResolver(ScoutInputFormSchema),
  schema: ScoutInputFormSchema,
} as const;
