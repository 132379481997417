import { UserRole, UserRoleKey } from '@app/types/organization';
import {
  Attachment,
  User,
  UserTypeId,
  Gender,
  GenderKey,
  Occupation,
  OccupationKey,
  ReceiveGift,
  ReceiveGiftKey,
  IdentificationStatus,
  IdentificationStatusKey,
  BankAccountType,
  BankAccountTypeKey,
} from '@app/types/user';
import { USER_ROLES_LABELS, USER_STATUS_LABELS } from '@app/utils/constants';

export function getStatusLabel(isLocked?: boolean): string {
  if (isLocked === undefined) {
    return '-';
  } else if (isLocked) {
    return USER_STATUS_LABELS.LOCKED;
  }
  return USER_STATUS_LABELS.NORMAL;
}

export const ROLE_OPTIONS: { label: string; value: string }[] = [
  { label: getRoleLabel(UserRole.OWNER), value: UserRole.OWNER },
  {
    label: getRoleLabel(UserRole.MEMBER),
    value: UserRole.MEMBER,
  },
];

/**
 * 権限ラベルを取得
 */
export function getRoleLabel(role?: UserRoleKey): string {
  switch (role) {
    case UserRole.ADMIN:
      return '-';
    case UserRole.OWNER:
      return USER_ROLES_LABELS.OWNER;
    case UserRole.MEMBER:
      return USER_ROLES_LABELS.MEMBER;
    case UserRole.GUEST:
      return '-';
    default:
      return '-';
  }
}

/**
 * ユーザー情報からロール値を取得する
 */
export function getRoleByUser(
  orgId: string,
  user: User
): UserRoleKey | undefined {
  const org = user.organizations?.find((org) => org.id === orgId);
  return org?.users?.find((u) => u.id === user.id)?.role;
}

/**
 * ユーザー情報からロールのラベルを取得する
 */
export function getRoleLabelByUser(orgId: string, user: User): string {
  const role = getRoleByUser(orgId, user);
  return role ? getRoleLabel(role) : '-';
}

/**
 * 対象ユーザーが削除可能か判定する
 */
export function checkUserDeleteOrLock(user: User, selfId?: string): boolean {
  // ログインユーザー本人は不可
  if (selfId && user.id === selfId) {
    return false;
  }
  // 所属する組織で管理者権限を０件にさせない
  const isCheckOwner = !user.organizations?.some((org) => {
    const owners = org.users?.filter((u) => u.role === UserRole.OWNER);
    const role = owners?.find((o) => o.id === user.id)?.role;
    return role === UserRole.OWNER && Number(owners?.length) <= 1;
  });
  if (!isCheckOwner) {
    return false;
  }
  return true;
}

/**
 * ユーザーのフルネームを取得する
 */
export function getFullName(
  user?: User,
  separate = '',
  isKana = false
): string {
  return [
    (isKana
      ? user?.customFields?.familyNameKana
      : user?.customFields?.familyName) || '',
    (isKana
      ? user?.customFields?.firstNameKana
      : user?.customFields?.firstName) || '',
  ]
    .filter((i) => !!i)
    .join(separate);
}

/**
 * ユーザーのフル住所を取得する
 */
export function getFullAddress(user?: User): string {
  return [
    user?.addressLine1,
    user?.addressLine2,
    user?.addressLine3,
    user?.customFields?.addressLine4,
  ]
    .filter((i) => !!i)
    .join('');
}

/**
 * Supplyユーザーか判定する
 */
export function isVendorUser(typeId: string) {
  return typeId === UserTypeId.SUPPLY || typeId === UserTypeId.SUPPLY_SUB;
}

/**
 * Adminユーザーか判定する
 */
export function isAdminUser(typeId: string) {
  return typeId === UserTypeId.ADMIN;
}

/**
 * 配列のAttachmentから指定したIDのAttachmentを取得する
 */
export function getAttachmentById(
  attachments: Attachment[],
  id?: string
): Attachment | undefined {
  return attachments.find((attachment) => attachment.id === id);
}

/**
 * 性別のラベルを取得する
 */
export function getGenderLabel(value?: GenderKey): string {
  switch (value) {
    case Gender.MAN:
      return '男性';
    case Gender.WOMAN:
      return '女性';
  }
  return '';
}

/**
 * 現在の状況のラベルを取得する
 */
export function getOccupationLabel(value?: OccupationKey): string {
  switch (value) {
    case Occupation.STUDENT:
      return '学生';
    case Occupation.GRADUATION_UNQUALIFIED:
      return '卒業(無資格)';
    case Occupation.GRADUATION_QUALIFIED:
      return '卒業(有資格)';
    case Occupation.EXPERIENCED_QUALIFIED:
      return '経験者(有資格)';
  }
  return '';
}

/**
 * 参加賞・賞金のラベルを取得する
 */
export function getGiftLabel(value?: ReceiveGiftKey): string {
  switch (value) {
    case ReceiveGift.CASH:
      return '現金';
    case ReceiveGift.AMAZON_GIFT:
      return 'amazonギフト券';
    case ReceiveGift.QUO_CARD:
      return 'QUOカード';
    case ReceiveGift.UNNECESSARY:
      return '受け取らない';
  }
  return '';
}

/**
 * 本人確認ステータスのラベルを取得する
 */
export function getIdentificationStatusLabel(
  value?: IdentificationStatusKey
): string {
  switch (value) {
    case IdentificationStatus.EMPTY:
      return '未提出';
    case IdentificationStatus.CONFIRM:
      return '確認待ち';
    case IdentificationStatus.REMAND:
      return '差戻';
    case IdentificationStatus.COMPLETE:
      return '承認';
  }
  return '';
}
export const identificationStatusOptions: { label: string; value: string }[] = [
  {
    label: getIdentificationStatusLabel(IdentificationStatus.CONFIRM),
    value: IdentificationStatus.CONFIRM,
  },
  {
    label: getIdentificationStatusLabel(IdentificationStatus.REMAND),
    value: IdentificationStatus.REMAND,
  },
  {
    label: getIdentificationStatusLabel(IdentificationStatus.COMPLETE),
    value: IdentificationStatus.COMPLETE,
  },
];

/**
 * 大学の選択肢
 */
export const schoolNameOptions = [
  '岡山理科大学',
  '岩手大学',
  '岐阜大学',
  '宮崎大学',
  '鹿児島大学',
  '千葉科学大学',
  '帯広畜産大学',
  '大阪府立大学',
  '東京大学',
  '東京農工大学',
  '日本獣医生命科学大学',
  '日本大学',
  '北海道大学',
  '北里大学',
  '麻布大学',
  '酪農学園大学',
];

/**
 * 口座種類のラベルを取得する
 */
export function getBankAccountTypeLabel(value?: BankAccountTypeKey): string {
  switch (value) {
    case BankAccountType.SAVING:
      return '普通口座';
    case BankAccountType.CHECKING:
      return '預金口座';
  }
  return '';
}
