import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import { forwardRef, ForwardedRef, ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface FormRadioGroupProps extends RadioGroupProps {
  name: string;
  options: {
    label: string;
    value: string;
  }[];
  required?: boolean;
  row?: boolean;
  title?: string;
}

export const FormRadioGroup = forwardRef(function (
  {
    name,
    options,
    required,
    row = false,
    title,
    ...props
  }: FormRadioGroupProps,
  ref: ForwardedRef<unknown>
): ReactElement {
  const { control } = useFormContext();

  return (
    <FormControl fullWidth>
      {!!title && (
        <FormLabel focused={false} className={required ? 'required-label' : ''}>
          {title}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <RadioGroup {...field} row={row} {...props}>
              {options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  sx={{ mr: 4 }}
                />
              ))}
            </RadioGroup>
            {error && (
              <FormHelperText error>{error.message as string}</FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
});
