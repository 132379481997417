import {
  FormControl,
  FormLabel,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { forwardRef, ForwardedRef, ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface FormTextFieldProps extends Omit<TextFieldProps, 'name'> {
  name: string;
  required?: boolean;
  title?: string;
}

export const FormTextField = forwardRef(function (
  { name, required, title, ...props }: FormTextFieldProps,
  ref: ForwardedRef<unknown>
): ReactElement {
  const { control } = useFormContext();

  return (
    <FormControl fullWidth>
      {!!title && (
        <FormLabel focused={false} className={required ? 'required-label' : ''}>
          {title}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={!!error}
            helperText={error?.message}
            {...props}
          />
        )}
      />
    </FormControl>
  );
});
