import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Stack,
} from '@mui/material';
import { forwardRef, ForwardedRef, ReactElement } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
  useWatch,
} from 'react-hook-form';

export interface CheckboxOption {
  child?: { options: Omit<CheckboxOption, 'children'>[]; row?: boolean };
  label: string;
  value: string;
}

export interface FormCheckboxProps extends Omit<CheckboxProps, 'name'> {
  name: string;
  options: CheckboxOption[];
  required?: boolean;
  row?: boolean;
  title?: string;
}

export const FormCheckbox = forwardRef(function (
  { name, options, required, row = false, title, ...props }: FormCheckboxProps,
  ref: ForwardedRef<unknown>
): ReactElement {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const watchValue = useWatch({ control, name }) || [];

  const handleChange = (
    field: ControllerRenderProps,
    value: string | undefined,
    checked: boolean,
    children?: CheckboxOption[]
  ) => {
    let newValue = checked
      ? [...field.value, value]
      : field.value.filter((v: string) => v !== value);

    if (children) {
      const childValues = children.map((c) => c.value);
      newValue = checked
        ? [...field.value, ...childValues]
        : newValue.filter((v: string) => !childValues.includes(v));
    }

    field.onChange(newValue);
  };

  return (
    <FormControl fullWidth>
      {!!title && (
        <FormLabel focused={false} className={required ? 'required-label' : ''}>
          {title}
        </FormLabel>
      )}
      <FormGroup row={row}>
        {options.map(({ label, value, child }, index) => (
          <Controller
            key={index}
            name={name}
            control={control}
            render={({ field }) => {
              const isChecked = child
                ? child.options.every((option) =>
                    watchValue.includes(option.value)
                  )
                : watchValue.includes(value);
              const isIndeterminate = Boolean(
                child &&
                  !isChecked &&
                  child.options.some((option) =>
                    watchValue.includes(option.value)
                  )
              );
              return (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={isChecked}
                        indeterminate={isIndeterminate}
                        onChange={(e) =>
                          handleChange(
                            field,
                            value,
                            e.target.checked,
                            child?.options
                          )
                        }
                        {...props}
                      />
                    }
                    label={label}
                    sx={{ ml: -0.5, mr: 3.5, width: 'fit-content' }}
                  />
                  {!!child && (
                    <Stack direction={child.row ? 'row' : 'column'} ml={3}>
                      {child.options.map((option, childIndex) => (
                        <Controller
                          key={childIndex}
                          name={name}
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  value={option.value}
                                  checked={watchValue.includes(option.value)}
                                  onChange={(e) =>
                                    handleChange(
                                      field,
                                      option.value,
                                      e.target.checked
                                    )
                                  }
                                  {...props}
                                />
                              }
                              label={option.label}
                              sx={{ ml: -0.5, mr: 3.5, width: 'fit-content' }}
                            />
                          )}
                        />
                      ))}
                    </Stack>
                  )}
                </div>
              );
            }}
          />
        ))}
      </FormGroup>
      {errors && (
        <FormHelperText error>
          {errors?.[name]?.message as string}
        </FormHelperText>
      )}
    </FormControl>
  );
});
