import { addDays, addYears, endOfDay } from 'date-fns';

import {
  CategoryName,
  Product,
  ProductImage,
  ProductLocation as Location,
  ProductOrientationType as OrientationType,
  ProductOrientationTypeKey as OrientationTypeKey,
  ProductStatus,
  ProductStatusKey,
  ProductSubStatus,
  ProductSubStatusKey,
  ProductSchedule as Schedule,
  ProductScheduleOccupation as Occupation,
  ProductTargetPerson as TargetPerson,
  ProductTargetPersonKey as TargetPersonKey,
} from '@app/types/catalog';

// サブステータスのキャンセルリスト
export const cancelSubStatus: ProductSubStatusKey[] = [
  ProductSubStatus.CANCEL,
  ProductSubStatus.REJECT,
  ProductSubStatus.ABSENT,
];

export function replaceProductImages(product: Product): Product {
  return {
    ...product,
    images: product.imageIds
      ?.map((id: string) =>
        product.images?.find((image: ProductImage) => image.id === id)
      )
      .filter((i) => !!i) as ProductImage[],
  };
}

/**
 * Product ステータスによる掲載開始日を返す
 */
export function getPublicationSince(
  status?: ProductStatusKey,
  currentSince?: string
): string | undefined {
  return status === ProductStatus.ACTIVE && !currentSince
    ? new Date().toISOString()
    : currentSince;
}

/**
 * Product ステータスによる掲載終了日を返す
 */
export function getPublicationUntil(
  status?: ProductStatusKey,
  currentUntil?: string,
  propertyFlg = false
): string | undefined {
  switch (status) {
    case ProductStatus.ACTIVE:
      return propertyFlg
        ? addDays(endOfDay(new Date()), 7).toISOString()
        : addYears(endOfDay(new Date()), 10).toISOString();
    case ProductStatus.DRAFT:
      return currentUntil;
  }
  return currentUntil;
}

/**
 * 都道府県 ID の配列を都道府県名のカンマ区切り文字列に変換する関数
 * @param constructionAreaIds
 * @param prefectures
 * @returns 都道府県名のカンマ区切り文字列または「全国」
 */
export function getConstructionAreaName(
  constructionAreaIds: string[] | undefined,
  prefectures: Location[],
  separator = ','
): string {
  if (!Array.isArray(constructionAreaIds) || prefectures.length === 0) {
    return '';
  }

  if (constructionAreaIds.length === prefectures.length) {
    const allPrefectureIds = prefectures.map((pref) => pref.id);
    const isAllSelected = allPrefectureIds.every((id) =>
      constructionAreaIds.includes(id)
    );
    if (isAllSelected) return '全国';
  }

  return constructionAreaIds
    .map((id) => prefectures.find((pref) => pref.id === id)?.name || id)
    .join(separator);
}

/**
 * 説明会タイプ別のURLパス名を取得
 */
export function getOrientationTypePath(value: OrientationTypeKey): string {
  switch (value) {
    case OrientationType.OFFLINE:
      return 'offline-orientation';
    case OrientationType.ONLINE:
      return 'online-orientation';
  }
  return '';
}

/**
 * 説明会タイプのラベルを取得
 */
export function getOrientationTypeLabel(value: OrientationTypeKey): string {
  switch (value) {
    case OrientationType.OFFLINE:
      return '現地';
    case OrientationType.ONLINE:
      return 'オンライン';
  }
  return '';
}

/**
 * 説明会の開催場所を取得
 */
export function getLocationLabel(product: Product): string {
  switch (product.customFields?.orientationType) {
    case OrientationType.OFFLINE:
      return product.addressLine1 || '';
    case OrientationType.ONLINE:
      return 'オンライン';
  }
  return '';
}

/**
 * 説明会のサブステータスラベルを取得
 */
export function getSubStatusLabel(product: Product): string {
  if (product.customFields?.category === CategoryName.ORIENTATION) {
    switch (product.publication.status) {
      case ProductStatus.DRAFT:
        switch (product.customFields?.subStatus) {
          case ProductSubStatus.RECRUITING:
          case ProductSubStatus.CONFIRM:
          case ProductSubStatus.SUPPLY_CLOSED:
            return '掲載中';
          case ProductSubStatus.DEMAND_CLOSED:
            return '掲載終了';
        }
        return '下書き';
      case ProductStatus.ACTIVE:
        return '掲載中';
      case ProductStatus.ARCHIVED:
        return '掲載終了';
    }
  } else if (product.customFields?.category === CategoryName.ORIENTATION_SUB) {
    switch (product.customFields?.subStatus) {
      case ProductSubStatus.APPLY:
        return '応募中';
      case ProductSubStatus.APPROVE:
        return '許可';
      case ProductSubStatus.CANCEL:
        return '辞退';
      case ProductSubStatus.REJECT:
        return '拒否';
      case ProductSubStatus.ABSENT:
        return '欠席';
    }
  }
  return '';
}

/**
 * 説明会の対象者ラベルを取得
 */
export const targetPersonLabel = {
  [TargetPerson.VETERINARY_STUDENT]: '獣医学生',
  [TargetPerson.PET_NURSING_STUDENT]: '愛玩動物看護学生',
  [TargetPerson.GRADUATION_QUALIFIED]: '既卒(有資格)',
  [TargetPerson.GRADUATION_UNQUALIFIED]: '既卒(無資格)',
  [TargetPerson.EXPERIENCED_QUALIFIED]: '経験者',
};
export function getTargetPersonLabel(value: string): string {
  const values = value.split('_');
  switch (values[0]) {
    case TargetPerson.VETERINARY_STUDENT:
    case TargetPerson.PET_NURSING_STUDENT: {
      const label = targetPersonLabel[values[0] as TargetPersonKey];
      return label ? label + (values[1] ? ` ${values[1]}年生` : '') : '';
    }
  }
  return targetPersonLabel[value as TargetPersonKey] || '';
}

/**
 * targetPersonsを指定の順序で並び替える
 */
export const sortTargetPersons = (values: string[]): string[] => {
  const orderMap = Object.keys(targetPersonLabel).reduce((acc, key, index) => {
    acc[key] = index;
    return acc;
  }, {} as { [key: string]: number });

  return values.sort((a, b) => {
    const [aBase, aNum] = a.split('_');
    const [bBase, bNum] = b.split('_');

    // 基本的な順序の比較
    const orderDiff =
      (orderMap[aBase] ?? Infinity) - (orderMap[bBase] ?? Infinity);
    if (orderDiff !== 0) return orderDiff;

    // 学生の場合は学年で降順に比較
    if (aNum && bNum) {
      return parseInt(bNum) - parseInt(aNum);
    }

    return 0;
  });
};

/**
 * サブステータスがキャンセルかを判定する
 */
export function isStatusCancel(product: Product): boolean {
  const subStatus = product.customFields?.subStatus;
  return !!subStatus && cancelSubStatus.includes(subStatus);
}

export const getOccupationsLabel = (
  schedules?: Schedule[],
  separator = '、'
) => {
  const occupations = Array.from(
    new Set(schedules?.map((schedule) => schedule?.occupations).flat())
  ).filter((o) => !!o) as string[];
  return occupations.map((o) => Occupation[o]).join(separator);
};
