import { Chip, Tab, Tabs, TabsProps } from '@mui/material';
import { ReactElement } from 'react';

import { theme } from '@app/theme';

export interface ButtonTabsProps extends TabsProps {
  tabs: {
    batch?: number;
    label: string;
    value: string;
  }[];
}

export function ButtonTabs({
  tabs,
  value,
  ...props
}: ButtonTabsProps): ReactElement {
  return (
    <Tabs
      value={value || tabs[0].value}
      variant="fullWidth"
      sx={{
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px 4px 0 0',
      }}
      TabIndicatorProps={{ style: { display: 'none' } }}
      {...props}
    >
      {tabs.map(({ label, value, batch }, index) => (
        <Tab
          key={index}
          label={label}
          value={value}
          icon={
            <>
              {!!batch && (
                <Chip
                  label={batch && batch > 99 ? 99 : batch}
                  color="error"
                  size="small"
                  sx={{
                    '& .MuiChip-label': { padding: 0 },
                    fontSize: 10,
                    height: 18,
                    ml: 1,
                    width: 18,
                  }}
                />
              )}
            </>
          }
          iconPosition="end"
          sx={{
            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              fontWeight: 400,
            },
            borderRight: tabs.length !== index + 1 ? 1 : 0,
            color: theme.palette.primary.main,
            paddingY: 1.25,
          }}
        />
      ))}
    </Tabs>
  );
}
