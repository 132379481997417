import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';
import { forwardRef, ForwardedRef, ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface FormSelectProps extends Omit<SelectProps, 'name'> {
  name: string;
  options: {
    label: string;
    value: string;
  }[];
  placeholder?: string;
  required?: boolean;
  title?: string;
}

export const FormSelect = forwardRef(function (
  {
    name,
    options,
    placeholder,
    required,
    title,
    value,
    ...props
  }: FormSelectProps,
  ref: ForwardedRef<unknown>
): ReactElement {
  const { control } = useFormContext();

  return (
    <FormControl fullWidth>
      {!!title && (
        <FormLabel focused={false} className={required ? 'required-label' : ''}>
          {title}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <Select
              {...field}
              value={field.value || ''}
              error={!!error}
              displayEmpty
              renderValue={(value: unknown) => {
                if (value)
                  return options.find((o) => o.value === value)?.label || '';
                return <Typography color="secondary">{placeholder}</Typography>;
              }}
              {...props}
            >
              <MenuItem value="">{'　'}</MenuItem>
              {options.map(({ label, value }, index) => (
                <MenuItem key={index} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            {error && (
              <FormHelperText error>{error.message as string}</FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
});
