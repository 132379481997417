import { Card, Divider, Stack } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { OrientationApplyButton } from '@app/components/Catalog/OrientationApplyButton';
import { OrientationInfo } from '@app/components/Catalog/OrientationInfo';
import { ProductStatusLabel } from '@app/components/Catalog/ProductStatusLabel';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { isLoading } from '@app/domain/app';
import { useOrientationProduct } from '@app/hooks/useOrientationProduct';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';

export function OrientationDetail() {
  const navigate = useNavigate();
  const { productId } = useParams();
  const setSnackbar = useSetSnackbar();
  const setLoadingState = useSetRecoilState(isLoading);
  const { childProducts, fetchChildProducts, fetchProduct, product } =
    useOrientationProduct(productId);

  const dataRefresh = useCallback(async () => {
    let data;
    if (productId) {
      setLoadingState(true);
      data = await fetchProduct(productId, true);
      if (data) {
        await fetchChildProducts(data.id);
      }
      setLoadingState(false);
    }

    if (!data) {
      setSnackbar(true, 'データが見つかりませんでした', 'error');
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    void dataRefresh();
  }, [dataRefresh]);

  return (
    <>
      <PageTitle
        title="説明会管理"
        onClick={() => navigate('/orientation-products')}
      />
      {product && (
        <Card>
          <Stack direction="row" spacing={2} p={3}>
            <div style={{ flexGrow: 1 }}>
              <ProductStatusLabel product={product} />
            </div>
            <OrientationApplyButton
              color="primary"
              parentProduct={product}
              disabled={!!childProducts.length}
              onChange={dataRefresh}
              sx={{ width: 100 }}
            />
          </Stack>
          <Divider />
          <OrientationInfo product={product} />
        </Card>
      )}
    </>
  );
}
