import { RemoveRedEyeOutlined as RemoveRedEyeOutlinedIcon } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import { ReactElement } from 'react';

import { theme } from '@app/theme';

export type DetailButtonProps = ButtonProps;

export function DetailButton({
  onClick,
  ...props
}: DetailButtonProps): ReactElement {
  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(event);
      }}
      {...props}
    >
      <RemoveRedEyeOutlinedIcon
        sx={{ color: theme.customPalette.gray2, fontSize: 17 }}
      />
    </Button>
  );
}
