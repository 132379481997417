import { useCallback, useState } from 'react';

import {
  GetProductsOption,
  getProducts,
  updateProduct,
} from '@app/adapter/catalog-service';
import { useProduct } from '@app/hooks/useProduct';
import {
  Product,
  ProductUpdate,
  ProductStatus as Status,
  ProductSubStatus as SubStatus,
} from '@app/types/catalog';
import { ADMIN_ORGANIZATION_ID } from '@app/utils/constants';
import { isError } from '@app/utils/error';

export const useOrientationProduct = (id?: string) => {
  const {
    fetchProduct,
    isLoading,
    isUpdating,
    product,
    setProduct,
    setUpdating,
    updateOrCreate,
  } = useProduct(ADMIN_ORGANIZATION_ID || '', id);

  const [childProducts, setChildProducts] = useState<Product[]>([]);
  const fetchChildProducts = useCallback(
    async (parentId?: string, option?: GetProductsOption) => {
      const targetId = parentId || id;
      if (!targetId) {
        return;
      }

      try {
        const {
          data: { value },
        } = await getProducts(undefined, {
          expand: 'organization',
          orderBy: 'createdAt desc',
          pageSize: 100,
          ...option,
          filter: {
            parentId: targetId,
            ...option?.filter,
          },
        });
        setChildProducts(value);
        return value;
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
      }
    },
    [id]
  );

  /**
   * 掲載ステータスの更新
   */
  const updateStatus = useCallback(
    async (values: ProductUpdate, currentProduct?: Product) => {
      try {
        const target = currentProduct || product;
        if (!target) {
          throw new Error('指定のデータがありません');
        }
        setUpdating(true);

        const { data } = await updateProduct(target.id, values);
        const latestProduct = {
          ...target,
          publication: data.publication,
          updatedAt: data.updatedAt,
        };

        // Supplyの説明会データも更新
        if (
          values.publication?.status === Status.ACTIVE ||
          values.customFields?.subStatus === SubStatus.CONFIRM
        ) {
          const children = await fetchChildProducts(target.id, {
            filter: {
              subStatus: [SubStatus.APPROVE, SubStatus.CONFIRM],
            },
          });
          if (children?.length) {
            await Promise.all(
              children.map(async (child) => {
                await updateProduct(child.id, values);
              })
            );
          }
        }

        setProduct(latestProduct);
        return latestProduct;
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
      } finally {
        setUpdating(false);
      }
    },
    [product, setProduct, setUpdating, fetchChildProducts]
  );

  return {
    childProducts,
    fetchChildProducts,
    fetchProduct,
    isLoading,
    isUpdating,
    product,
    setProduct,
    updateOrCreate,
    updateStatus,
  };
};
