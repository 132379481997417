import { Organization, UserRoleKey } from '@app/types/organization';
import { ReviewStats } from '@app/types/review';

/**
 * User
 */
export interface User {
  accessToken?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  avatar?: string;
  createdAt: string;
  customFields?: UserCustomFields;
  email: string;
  emailVerified: boolean;
  id: string;
  isLocked: boolean;
  name: string;
  organizations?: Organization[];
  phoneNumber?: string;
  provider?: string;
  providerId?: string;
  reviewStats?: ReviewStats;
  role?: UserRoleKey;
  typeId: string;
  updatedAt: string;
}

export interface UserCustomFields {
  addressLine4?: string;
  bankAccountName?: string;
  bankAccountNumber?: string;
  bankAccountType?: BankAccountTypeKey;
  bankBranchName?: string;
  bankName?: string;
  birthday?: string;
  birthplace?: string;
  certificateAttachmentId?: string;
  desiredJob?: DesiredJobKey;
  diplomaAttachmentId?: string;
  familyName?: string;
  familyNameKana?: string;
  firstName?: string;
  firstNameKana?: string;
  gender?: GenderKey;
  graduationYear?: number;
  identificationNote?: string;
  identificationStatus?: IdentificationStatusKey;
  laboratory?: string;
  licenseAttachmentId?: string;
  occupation?: OccupationKey;
  postalCode?: string;
  promotion?: string;
  receiveGift?: ReceiveGiftKey;
  schoolDepartment?: string;
  schoolName?: string;
  schoolYear?: number;
  scoutAllowFlag?: boolean;
  studentCardAttachmentId?: string;
}

export type UserCreate = Omit<
  User,
  'id' | 'createdAt' | 'updatedAt' | 'emailVerified' | 'isLocked'
> & {
  password: string;
};

export type UserUpdate = Partial<UserCreate>;

export const UserTypeId = {
  ADMIN: '100',
  DEMAND: '000',
  SUPPLY: '010',
  SUPPLY_SUB: '001',
};
export type TypeIdKey = (typeof UserTypeId)[keyof typeof UserTypeId];

export const Gender = {
  MAN: 'man',
  WOMAN: 'woman',
};
export type GenderKey = (typeof Gender)[keyof typeof Gender];

export const Occupation = {
  EXPERIENCED_QUALIFIED: 'experiencedQualified',
  GRADUATION_QUALIFIED: 'graduationQualified',
  GRADUATION_UNQUALIFIED: 'graduationUnqualified',
  STUDENT: 'student',
};
export type OccupationKey = (typeof Occupation)[keyof typeof Occupation];

export const DesiredJob: {
  [key: string]: string;
} = {
  '01': '獣医師',
  '02': '愛玩動物看護師',
  '03': '動物看護助手',
  '04': '受付',
  '05': 'トリマー',
  '06': 'その他',
} as const;
export type DesiredJobKey = (typeof DesiredJob)[keyof typeof DesiredJob];

export const ReceiveGift = {
  AMAZON_GIFT: 'amazonGift',
  CASH: 'cash',
  QUO_CARD: 'quoCard',
  UNNECESSARY: 'unnecessary',
} as const;
export type ReceiveGiftKey = (typeof ReceiveGift)[keyof typeof ReceiveGift];

export const IdentificationStatus = {
  COMPLETE: 'complete',
  CONFIRM: 'confirm',
  EMPTY: 'empty',
  REMAND: 'remand',
} as const;
export type IdentificationStatusKey =
  (typeof IdentificationStatus)[keyof typeof IdentificationStatus];

export const BankAccountType = {
  CHECKING: 'checking',
  SAVING: 'saving',
} as const;
export type BankAccountTypeKey =
  (typeof BankAccountType)[keyof typeof BankAccountType];

/**
 * Attachment
 */
export interface Attachment {
  id?: string;
  ownerId?: string;
  ownerType?: string;
  type?: string;
  url?: string;
}
