import { Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

export function EmailSent(): ReactElement {
  return (
    <Stack spacing={4}>
      <Typography variant="h6" textAlign="center" component="div">
        パスワード再設定手続きの
        <br />
        メールをお送りしました
      </Typography>
      <Typography>
        ご登録メールアドレス宛にパスワード再発行手続きのメールをお送りしました。
      </Typography>
      <Typography>
        メール本文に記述されているURLをクリックして頂き、パスワード再設定手続きを行ってください。
      </Typography>
    </Stack>
  );
}
