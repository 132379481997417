import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { sendResetPasswordEmail } from '@app/adapter/user-service';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import {
  PasswordResetEmailForm,
  PasswordResetEmailFormData,
} from '@app/schemas/user';
import { isError } from '@app/utils/error';

export function EmailForm(): ReactElement {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<PasswordResetEmailFormData>(PasswordResetEmailForm);

  const handleSubmitForm = async (formData: PasswordResetEmailFormData) => {
    try {
      setIsLoading(true);

      await sendResetPasswordEmail(formData.email);

      navigate('/password/reset/email-sent');
    } catch (error) {
      if (isError(error)) {
        console.error(error.message);
        if (error.message === 'email not found') {
          setSnackbar(
            true,
            '入力されたメールアドレスの会員情報が見つかりません。間違ったメールアドレスを入力されていないかご確認ください',
            'error'
          );
          return;
        }
        setSnackbar(true, 'メールの送信に失敗しました', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack spacing={4}>
        <Typography variant="h6" textAlign="center">
          パスワード再設定手続き
        </Typography>
        <Typography>
          ご登録していただいたメールアドレスを入力してください。パスワード再発行手続きのメールをお送りします。
        </Typography>
        <FormControl fullWidth>
          <FormLabel focused={false}>メールアドレス</FormLabel>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={!!error}
                helperText={error?.message}
                placeholder="メールアドレスを入力"
              />
            )}
          />
        </FormControl>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          loading={isLoading}
          disabled={!isValid}
          fullWidth
        >
          送信
        </LoadingButton>
      </Stack>
    </form>
  );
}
