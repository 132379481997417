import { Backdrop, CircularProgress } from '@mui/material';
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { DefaultLayout } from '@app/components/Layout/DefaultLayout';
import { VendorLayout } from '@app/components/Layout/VendorLayout';
import { Login } from '@app/views/Login';
import { NotFound } from '@app/views/NotFound';
import { OrientationDetail as OrientationOrder } from '@app/views/Orders/OrientationDetail';
import { OrientationIndex as OrientationOrders } from '@app/views/Orders/OrientationIndex';
import { OrganizationUserRoute } from '@app/views/OrganizationUserRoute';
import { EmailForm } from '@app/views/PasswordReset/EmailForm';
import { EmailSent } from '@app/views/PasswordReset/EmailSent';
import { PasswordForm } from '@app/views/PasswordReset/PasswordForm';
import { OrientationDetail as OrientationProduct } from '@app/views/Products/OrientationDetail';
import { OrientationIndex as OrientationProducts } from '@app/views/Products/OrientationIndex';

export function App() {
  return (
    <RecoilRoot>
      <Suspense
        fallback={
          <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      >
        <Router>
          <Routes>
            <Route path="/">
              {/* Anonymous users */}
              <Route element={<DefaultLayout />}>
                <Route path="/login" element={<Login />} />
                <Route path="/password">
                  <Route path="reset">
                    <Route index element={<EmailForm />} />
                    <Route path="email-sent" element={<EmailSent />} />
                    <Route path="verify" element={<PasswordForm />} />
                  </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
              </Route>
              {/* Confirmed users who already have their organization */}
              <Route
                element={
                  //TODO: REFACTOR SEPARATE LOGIC FROM ROUTING
                  <OrganizationUserRoute>
                    <VendorLayout />
                  </OrganizationUserRoute>
                }
              >
                <Route index element={<OrientationProducts />} />
                <Route path="/orientation-products">
                  <Route index element={<OrientationProducts />} />
                  <Route path=":productId">
                    <Route index element={<OrientationProduct />} />
                  </Route>
                </Route>
                <Route path="/orientation-orders">
                  <Route index element={<OrientationOrders />} />
                  <Route path=":groupKey">
                    <Route index element={<OrientationOrder />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </Router>
      </Suspense>
    </RecoilRoot>
  );
}
