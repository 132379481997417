export const getBase64 = async (
  url: string
): Promise<string | ArrayBuffer | null> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

export function filterSyntaxGen(list: string[]) {
  const formattedList = list.map((p) => `'${p}'`);
  return `[${formattedList.join(',')}]`;
}

export function convertNewLine(
  str: string | undefined,
  separator: string | RegExp = /\\r\\n|\\r|\\n/g
) {
  if (!str) {
    return '';
  }
  return str.replaceAll(separator, '\n');
}

export function getAge(birthday?: string) {
  if (!birthday) {
    return undefined;
  }
  const today = new Date();
  const birth = new Date(birthday);
  const age =
    today.getFullYear() -
    birth.getFullYear() +
    (today <
    new Date(today.getFullYear(), birth.getMonth() - 1, birth.getDate())
      ? -1
      : 0);
  return age;
}

export function uniqueArray(values: string[]): string[] {
  return values.filter((v, i) => values.indexOf(v) === i);
}

export function generateRandomPassword(length = 12): string {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charsetLength = charset.length;
  let password = '';
  const randomValues = window.crypto.getRandomValues(new Uint32Array(length));
  for (let i = 0; i < length; i++) {
    password += charset[randomValues[i] % charsetLength];
  }
  return password;
}

/**
 * 郵便番号をハイフン付きに変換する
 */
export function convertPostalCode(
  value: string | undefined,
  symbolFlag = false
): string {
  if (!value) return '';
  const postalCode = value.replace('-', '');
  return (
    (symbolFlag ? '〒 ' : '') +
    `${postalCode.substring(0, 3)}-${postalCode.substring(3, 7)}`
  );
}
