import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Button, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { theme } from '@app/theme';

interface PageTitleProps {
  buttonHidden?: boolean;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  title: string;
}

export function PageTitle({
  buttonHidden = false,
  onClick,
  sx,
  title,
}: PageTitleProps) {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      mb={3}
      sx={{ ...sx }}
    >
      {!buttonHidden && (
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => (onClick ? onClick() : navigate(-1))}
          sx={{ p: '7px' }}
        >
          <ArrowBackRoundedIcon sx={{ color: theme.customPalette.gray2 }} />
        </Button>
      )}
      <Typography variant="h5" fontWeight={400}>
        {title}
      </Typography>
    </Stack>
  );
}
