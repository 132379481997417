import { Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

import { DesiredJob, User } from '@app/types/user';
import { dateFormat } from '@app/utils/format';
import { getFullAddress, getFullName, getGenderLabel } from '@app/utils/user';

interface UserInfoProps {
  isHideAddress?: boolean;
  isHidePhone?: boolean;
  user: User;
}

export function UserInfo({
  isHideAddress = false,
  isHidePhone = false,
  user,
}: UserInfoProps): ReactElement {
  return (
    <Stack spacing={3}>
      <Typography variant="h6">会員登録情報</Typography>
      <Stack spacing={1.5}>
        <Typography variant="body2">■姓名</Typography>
        <Typography>{getFullName(user, ' ')}</Typography>
      </Stack>
      <Stack spacing={1.5}>
        <Typography variant="body2">■姓名（カナ）</Typography>
        <Typography>{getFullName(user, ' ', true)}</Typography>
      </Stack>
      <Stack spacing={1.5}>
        <Typography variant="body2">■メールアドレス</Typography>
        <Typography>{user.email || ''}</Typography>
      </Stack>
      <Stack spacing={1.5}>
        <Typography variant="body2">■性別</Typography>
        <Typography>{getGenderLabel(user.customFields?.gender)}</Typography>
      </Stack>
      <Stack spacing={1.5}>
        <Typography variant="body2">■生年月日</Typography>
        <Typography>
          {dateFormat(user.customFields?.birthday, 'yyyy年M月d日')}
        </Typography>
      </Stack>
      <Stack spacing={1.5}>
        <Typography variant="body2">■出身地</Typography>
        <Typography>{user.customFields?.birthplace || ''}</Typography>
      </Stack>
      <Stack spacing={1.5}>
        <Typography variant="body2">■学校名</Typography>
        <Typography>{user.customFields?.schoolName || ''}</Typography>
      </Stack>
      <Stack spacing={1.5}>
        <Typography variant="body2">■学科</Typography>
        <Typography>{user.customFields?.schoolDepartment || ''}</Typography>
      </Stack>
      <Stack spacing={1.5}>
        <Typography variant="body2">■学年</Typography>
        <Typography>
          {user.customFields?.schoolYear
            ? `${user.customFields.schoolYear}年`
            : ''}
        </Typography>
      </Stack>
      <Stack spacing={1.5}>
        <Typography variant="body2">■卒業/卒業予定年度</Typography>
        <Typography>
          {user.customFields?.graduationYear
            ? `${user.customFields.graduationYear}年度`
            : ''}
        </Typography>
      </Stack>
      <Stack spacing={1.5}>
        <Typography variant="body2">■希望職種</Typography>
        <Typography>
          {user.customFields?.desiredJob
            ? DesiredJob[user.customFields?.desiredJob] || ''
            : ''}
        </Typography>
      </Stack>
      <Stack spacing={1.5}>
        <Typography variant="body2">■研究室</Typography>
        <Typography>{user.customFields?.laboratory || ''}</Typography>
      </Stack>
      {!isHideAddress && (
        <Stack spacing={1.5}>
          <Typography variant="body2">■住所</Typography>
          <Typography>{getFullAddress(user)}</Typography>
        </Stack>
      )}
      {!isHidePhone && (
        <Stack spacing={1.5}>
          <Typography variant="body2">■連絡先電話番号</Typography>
          <Typography>{user.phoneNumber || ''}</Typography>
        </Stack>
      )}
    </Stack>
  );
}
