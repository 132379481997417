import { BorderColorOutlined as BorderColorOutlinedIcon } from '@mui/icons-material';
import { Stack, Typography, IconButton, IconButtonProps } from '@mui/material';
import { ReactElement } from 'react';

import { theme } from '@app/theme';

export interface EditIconTextProps extends IconButtonProps {
  label?: string;
}

export function EditIconText({
  label,
  onClick,
  ...props
}: EditIconTextProps): ReactElement {
  return (
    <Stack direction="row" spacing={0.25} alignItems="center">
      {label && <Typography variant="body2">{label}</Typography>}
      <IconButton
        size="small"
        onClick={(event) => {
          event.stopPropagation();
          onClick?.(event);
        }}
        {...props}
      >
        <BorderColorOutlinedIcon
          sx={{ color: theme.palette.tertiary.main, fontSize: 15 }}
        />
      </IconButton>
    </Stack>
  );
}
