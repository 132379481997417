import { LoadingButton } from '@mui/lab';
import { ButtonProps, Typography } from '@mui/material';
import { ReactElement, useCallback, useMemo, useState } from 'react';

import {
  SimpleDialog,
  SimpleDialogProps,
} from '@app/components/Shared/Dialog/SimpleDialog';
import { useOrientationProduct } from '@app/hooks/useOrientationProduct';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import {
  Product,
  ProductStatus as Status,
  ProductSubStatus as SubStatus,
  ProductSubStatusKey as SubStatusKey,
} from '@app/types/catalog';

export interface OrientationSubStatusButtonProps
  extends Omit<ButtonProps, 'onChange'> {
  label?: string;
  onChange?: (product?: Product) => void;
  product: Product;
  subStatus: SubStatusKey;
}

export function OrientationSubStatusButton({
  label,
  onChange,
  product,
  subStatus,
  ...props
}: OrientationSubStatusButtonProps): ReactElement {
  const setSnackbar = useSetSnackbar();
  const { isUpdating, setUpdating, updateStatus } = useOrientationProduct();
  const [isPublishModal, setPublishModal] = useState(false);

  const handleClickCancel = useCallback(() => {
    setPublishModal(false);
  }, [setPublishModal]);

  const handleClickSubmit = useCallback(async () => {
    setUpdating(true);
    try {
      switch (subStatus) {
        case SubStatus.CANCEL:
          await updateStatus(
            {
              customFields: { subStatus },
              publication: { status: Status.DRAFT },
            },
            product
          );
          break;
      }
      setSnackbar(true, '更新しました', 'success');
      onChange?.();
    } catch (error) {
      setSnackbar(true, '保存に失敗しました', 'error');
      console.error(error);
    } finally {
      setUpdating(false);
    }
  }, [onChange, product, subStatus, updateStatus, setSnackbar, setUpdating]);

  const handleClickButton = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      switch (subStatus) {
        case SubStatus.CANCEL:
          setPublishModal(true);
          break;
        default:
          void handleClickSubmit();
      }
    },
    [handleClickSubmit, subStatus]
  );

  const buttonLabel = useMemo(() => {
    if (label) {
      return label;
    }
    switch (subStatus) {
      case SubStatus.CANCEL:
        return '参加取消';
    }
    return '';
  }, [label, subStatus]);

  const modalProps = useMemo(() => {
    const setting: Omit<SimpleDialogProps, 'open'> = {
      submitLabel: '',
      title: '',
    };
    switch (subStatus) {
      case SubStatus.CANCEL:
        setting.title = '参加取消';
        setting.submitLabel = '取消';
        break;
    }
    return setting;
  }, [subStatus]);

  return (
    <>
      <LoadingButton
        variant="outlined"
        color="tertiary"
        loading={isUpdating}
        onClick={handleClickButton}
        {...props}
      >
        {buttonLabel}
      </LoadingButton>
      <SimpleDialog
        open={isPublishModal}
        cancelLabel="キャンセル"
        loading={isUpdating}
        fullWidth
        onClickSubmit={handleClickSubmit}
        onClickCancel={handleClickCancel}
        {...modalProps}
      >
        {subStatus === SubStatus.CANCEL ? (
          <Typography variant="body2">{`${product.name}への参加を取り消しますか？`}</Typography>
        ) : (
          <></>
        )}
      </SimpleDialog>
    </>
  );
}
