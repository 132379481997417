import { FormControl, FormLabel, PopperPlacementType } from '@mui/material';
import { forwardRef, ForwardedRef, ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  DatePicker,
  DatePickerProps,
} from '@app/components/Shared/Inputs/DatePicker';

export interface FormDateFieldProps extends Omit<DatePickerProps, 'name'> {
  name: string;
  popperPlacement?: PopperPlacementType;
  required?: boolean;
  title?: string;
}

export const FormDateField = forwardRef(function (
  {
    name,
    required,
    title,
    datePicker,
    popperPlacement = 'bottom',
    ...props
  }: FormDateFieldProps,
  ref: ForwardedRef<unknown>
): ReactElement {
  const { control } = useFormContext();

  return (
    <div>
      <FormControl>
        {!!title && (
          <FormLabel
            focused={false}
            className={required ? 'required-label' : ''}
          >
            {title}
          </FormLabel>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              {...field}
              error={!!error}
              actions={['clear']}
              helperText={error?.message}
              datePicker={{
                PopperProps: {
                  placement: popperPlacement,
                },
                value: field.value,
              }}
              readOnly
              {...props}
            />
          )}
        />
      </FormControl>
    </div>
  );
});
