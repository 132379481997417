import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import {
  GetProductsOption,
  getProducts,
  updateProduct,
} from '@app/adapter/catalog-service';
import { organization } from '@app/domain/organization';
import { useProduct } from '@app/hooks/useProduct';
import {
  Product,
  ProductUpdate,
  ProductStatus as Status,
  ProductSubStatus as SubStatus,
} from '@app/types/catalog';
import { isError } from '@app/utils/error';

export const useOrientationProduct = (id?: string) => {
  const currentOrg = useRecoilValue(organization);
  const {
    fetchProduct,
    isLoading,
    isUpdating,
    product,
    setProduct,
    setUpdating,
    updateOrCreate,
  } = useProduct(currentOrg?.id || '', id);

  const [childProducts, setChildProducts] = useState<Product[]>([]);
  const fetchChildProducts = useCallback(
    async (parentId?: string, option?: GetProductsOption) => {
      const targetId = parentId || id;
      if (!targetId || !currentOrg) {
        return;
      }

      try {
        const {
          data: { value },
        } = await getProducts(currentOrg.id, {
          orderBy: 'createdAt desc',
          pageSize: 100,
          ...option,
          filter: {
            parentId: targetId,
            ...option?.filter,
          },
        });
        setChildProducts(value);
        return value;
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
      }
    },
    [id, currentOrg]
  );

  /**
   * 掲載ステータスの更新
   */
  const updateStatus = useCallback(
    async (values: ProductUpdate, currentProduct?: Product) => {
      try {
        const target = currentProduct || product;
        if (!target) {
          throw new Error('指定のデータがありません');
        }
        setUpdating(true);

        const payload = {
          customFields: {
            subStatus: values.customFields?.subStatus || undefined,
            supplyConfirmClosingDate:
              values.customFields?.supplyConfirmClosingDate || undefined,
          },
          publication: {
            since: values.publication?.since || undefined,
            status: values.publication?.status,
            until: values.publication?.until || undefined,
          },
        };
        const { data } = await updateProduct(target.id, payload);
        const latestProduct = {
          ...target,
          publication: data.publication,
          updatedAt: data.updatedAt,
        };

        // Supplyの説明会データも更新
        if (
          payload.publication?.status === Status.ACTIVE ||
          payload.customFields.subStatus === SubStatus.DEMAND_CLOSED
        ) {
          const children = await fetchChildProducts(target.id, {
            filter: {
              subStatus: SubStatus.APPROVE,
            },
          });
          if (children?.length) {
            await Promise.all(
              children.map(async (child) => {
                await updateProduct(child.id, payload);
              })
            );
          }
        }

        setProduct(latestProduct);
        return latestProduct;
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
      } finally {
        setUpdating(false);
      }
    },
    [product, setProduct, setUpdating, fetchChildProducts]
  );

  return {
    childProducts,
    fetchChildProducts,
    fetchProduct,
    isLoading,
    isUpdating,
    product,
    setProduct,
    setUpdating,
    updateOrCreate,
    updateStatus,
  };
};
