import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { required } from '@app/schemas/base';
import { CategoryNameKey } from '@app/types/catalog';
import { Common, EmptyKey } from '@app/types/common';
import {
  OrderStatus,
  OrderStatusKey,
  OrderTransferStatus,
  OrderTransferStatusKey,
} from '@app/types/order';

/**
 * Order search form for admin
 */
export interface OrderSearchFormAdminData {
  sort: string;
  status: string;
}
export const OrderSearchFormAdminDefaultValues: OrderSearchFormAdminData = {
  sort: 'createdAt desc',
  status: '',
};
export const OrderSearchFormAdmin = {
  defaultValues: OrderSearchFormAdminDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      sort: z.string().optional(),
      status: z.string().optional(),
    })
  ),
} as const;

/**
 * Order search form
 */
export interface OrderSearchFormData {
  createdAtSince: string;
  createdAtUntil: string;
  scheduleDate: string;
  sort: string;
  status: OrderStatusKey | EmptyKey;
}
export const OrderSearchFormDefaultValues: OrderSearchFormData = {
  createdAtSince: '',
  createdAtUntil: '',
  scheduleDate: '',
  sort: 'createdAt desc',
  status: Common.EMPTY,
};
export const OrderSearchForm = {
  defaultValues: OrderSearchFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      createdAtSince: z.string(),
      createdAtUntil: z.string(),
      scheduleDate: z.string(),
      sort: z.string(),
      status: z.string(),
    })
  ),
} as const;

/**
 * Order CSV download form
 */
export interface OrderCsvDownloadFormKey {
  dateFrom: string;
  dateTo: string;
  organizationId: string;
  productCategory: CategoryNameKey | EmptyKey;
  status: OrderStatusKey;
}
export const OrderCsvDownloadFormDefaultValues: OrderCsvDownloadFormKey = {
  dateFrom: '',
  dateTo: '',
  organizationId: '',
  productCategory: Common.EMPTY,
  status: OrderStatus.PENDING,
};
export const OrderCsvDownloadForm = {
  defaultValues: OrderCsvDownloadFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      dateFrom: z.string().min(1),
      dateTo: z.string().min(1),
      organizationId: z.string().min(1),
      productCategory: z.string(),
      status: z.string(),
    })
  ),
} as const;

/**
 * ステータス更新フォーム
 */
export interface StatusEditFormData {
  status: OrderStatusKey;
}
export const StatusEditFormDefaultValues: StatusEditFormData = {
  status: OrderStatus.PENDING,
};
export const StatusEditForm = {
  defaultValues: StatusEditFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      status: required(undefined, true),
    })
  ),
} as const;

/**
 * 振込ステータス更新フォーム
 */
export interface TransferStatusEditFormData {
  transferStatus: OrderTransferStatusKey | undefined;
}
export const TransferStatusEditFormDefaultValues: TransferStatusEditFormData = {
  transferStatus: OrderTransferStatus.PENDING,
};
export const TransferStatusEditForm = {
  defaultValues: TransferStatusEditFormDefaultValues,
  mode: 'all',
  resolver: zodResolver(
    z.object({
      transferStatus: required(undefined, true),
    })
  ),
} as const;
