import { OpenInNewRounded as OpenInNewRoundedIcon } from '@mui/icons-material';
import { Link as MuiLink, LinkProps } from '@mui/material';
import { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface Props extends LinkProps {
  external?: boolean;
  to: string;
}
export function Link({
  to,
  external = false,
  children,
  target,
  ...props
}: Props): ReactElement {
  return (
    <MuiLink
      component={RouterLink}
      to={to}
      rel={external ? 'noopener noreferrer' : ''}
      target={target}
      {...props}
      sx={
        target === '_blank'
          ? {
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
            }
          : {}
      }
    >
      {children}
      {target === '_blank' && (
        <OpenInNewRoundedIcon fontSize="small" sx={{ ml: 0.5 }} />
      )}
    </MuiLink>
  );
}
