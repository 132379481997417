import { Stack, Typography } from '@mui/material';
import { ReactElement, Fragment } from 'react';

import { Product } from '@app/types/catalog';
import { Order } from '@app/types/order';
import { Organization } from '@app/types/organization';
import {
  getScheduleDateLabel,
  getBenefitReceivesLabel,
} from '@app/utils/order';
import { getBranchPrefecturesLabel } from '@app/utils/organization';

interface OrientationProductInfoProps {
  isAdmin?: boolean;
  order?: Order;
  subOrders: Order[];
}

export function OrientationProductInfo({
  isAdmin = false,
  order,
  subOrders,
}: OrientationProductInfoProps): ReactElement {
  const organizationName = (organization: Organization) => {
    const prefecturesLabel = getBranchPrefecturesLabel(
      organization.customFields?.branches
    );
    return `${organization.name || ''}${
      prefecturesLabel ? `（${prefecturesLabel}）` : ''
    }`;
  };

  return (
    <Stack spacing={3}>
      <Typography variant="h6">応募者情報</Typography>
      {isAdmin && (
        <>
          <Stack spacing={1.5}>
            <Typography variant="body2">■説明会タイトル</Typography>
            <Typography>
              {(order?.lineItems[0]?.product as Product)?.name || ''}
            </Typography>
          </Stack>
          <Stack spacing={1.5}>
            <Typography variant="body2">■参加する病院/日時</Typography>
            <Stack spacing={0.5}>
              {subOrders.map((subOrder, index) => {
                const subProduct = subOrder.lineItems[0].product as Product;
                return (
                  <div key={index}>
                    <div>・{getScheduleDateLabel(subOrder, true)}</div>
                    <div>{organizationName(subProduct.organization)}</div>
                  </div>
                );
              })}
            </Stack>
          </Stack>
        </>
      )}
      <Stack spacing={1.5}>
        <Typography variant="body2">■応募理由</Typography>
        <Stack spacing={0.5}>
          {subOrders.map((subOrder, index) => {
            const subProduct = subOrder.lineItems[0]?.product as Product;
            const reason = subOrder.customFields?.reason || '';
            return (
              <Fragment key={index}>
                {reason ? (
                  <div>
                    <div>{organizationName(subProduct.organization)}</div>
                    <div className="ow-break-word">{reason}</div>
                  </div>
                ) : (
                  <></>
                )}
              </Fragment>
            );
          })}
        </Stack>
      </Stack>
      {isAdmin && (
        <>
          <Stack spacing={1.5}>
            <Typography variant="body2">■紹介者</Typography>
            <Typography>{order?.customFields?.referrerName}</Typography>
          </Stack>
          <Stack spacing={1.5}>
            <Typography variant="body2">■参加賞・賞金の受取方法</Typography>
            <Typography>
              {getBenefitReceivesLabel(order?.customFields?.benefitReceive)}
            </Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
}
