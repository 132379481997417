import { Divider, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

import { OrientationAppliedForm } from '@app/administrator/components/Catalog/OrientationAppliedForm';
import {
  Product,
  ProductOrientationType as OrientationType,
  ProductTargetPerson as TargetPerson,
  ProductTargetPersonKey as TargetPersonKey,
} from '@app/types/catalog';
import { getOccupationsLabel, targetPersonLabel } from '@app/utils/catalog';

interface OrientationInfoProps {
  childProducts?: Product[];
  product: Product;
}

export function OrientationInfo({
  childProducts,
  product,
}: OrientationInfoProps): ReactElement {
  const targetPersonsLabel = (values: string[]): string => {
    // 値を種類ごとにグループ化
    const groupedValues = values.reduce((acc, value) => {
      const [base, grade] = value.split('_');
      if (!acc[base]) {
        acc[base] = [];
      }
      if (grade) {
        acc[base].push(grade);
      }
      return acc;
    }, {} as { [key: string]: string[] });

    // targetPersonLabelの順序でラベルを生成
    return Object.keys(targetPersonLabel)
      .map((key) => {
        if (!groupedValues[key]) return '';

        const baseLabel = targetPersonLabel[key as TargetPersonKey];

        // 学生の場合は学年を降順で結合
        if (
          key === TargetPerson.VETERINARY_STUDENT ||
          key === TargetPerson.PET_NURSING_STUDENT
        ) {
          if (groupedValues[key].length === 0) return baseLabel;
          const grades = [...groupedValues[key]]
            .sort((a, b) => parseInt(b) - parseInt(a))
            .map((grade) => `${grade}年生`)
            .join('、');
          return `${baseLabel} ${grades}`;
        }

        return baseLabel;
      })
      .filter((label) => label !== '')
      .join('\n');
  };

  return (
    <Stack spacing={4} p={3}>
      <div>
        <Typography variant="h6">説明会名</Typography>
        <Typography variant="body2">{product.name}</Typography>
      </div>
      <div>
        <Typography variant="h6">メイン画像</Typography>
        {!!product.images?.[0]?.url && (
          <img
            src={product.images[0].url}
            alt="メイン"
            width={200}
            height={150}
          />
        )}
      </div>
      <div>
        <Typography variant="h6">アンケート(Googleフォーム)</Typography>
        <Typography variant="body2">
          {product.customFields?.survey || ''}
        </Typography>
      </div>
      <div>
        <Typography variant="h6">病院応募締切日</Typography>
        <Typography variant="body2">
          {product.customFields?.supplyRecruitClosingDate || ''}
        </Typography>
      </div>
      {!!childProducts?.length && (
        <div>
          <Typography variant="h6">参加病院</Typography>
          <div>
            {product?.customFields?.schedules?.map((schedule, index) => {
              const children = childProducts.filter(
                (child) =>
                  !!child.customFields?.schedules?.find(
                    (childSchedule) => childSchedule.date === schedule.date
                  )
              );
              return (
                <OrientationAppliedForm
                  key={index}
                  schedule={schedule}
                  parentProduct={product}
                  products={children}
                  name={`schedules.${index}`}
                  readonly
                />
              );
            })}
          </div>
        </div>
      )}
      {product.customFields?.orientationType === OrientationType.OFFLINE && (
        <>
          <div>
            <Typography variant="h6">開催地域</Typography>
            <Typography variant="body2">
              {product.addressLine1 || ''}
            </Typography>
          </div>
          <div>
            <Typography variant="h6">開催場所住所</Typography>
            <Typography variant="body2">
              {product.customFields.access || ''}
            </Typography>
          </div>
        </>
      )}
      <div>
        <Typography variant="h6">開催日・時間・病院枠・職種</Typography>
        <Stack spacing={3} divider={<Divider />}>
          {product.customFields?.schedules?.map((schedule, index) => (
            <Typography key={index} variant="body2" component="div">
              <Stack spacing={2}>
                <div>・{schedule.date}</div>
                <div>
                  {schedule.times?.map((time, index) => (
                    <div key={index}>
                      {`${index + 1}回目：${
                        time.start && time.start?.length > 1 ? time.start : ''
                      }~${time.end && time.end?.length > 1 ? time.end : ''}`}
                    </div>
                  ))}
                  <div className="ow-break-word">{schedule.timeNote || ''}</div>
                </div>
                <div>参加病院枠：{schedule.orgMaxCount || 0}枠</div>
                <div>
                  職種：
                  {getOccupationsLabel([schedule])}
                </div>
                {product.customFields?.orientationType ===
                  OrientationType.ONLINE && (
                  <div>zoomURL：{schedule?.zoom || ''}</div>
                )}
              </Stack>
            </Typography>
          ))}
        </Stack>
      </div>
      <div>
        <Typography variant="h6">対象者</Typography>
        <Typography variant="body2" component="div">
          <div className="ow-break-word">
            {targetPersonsLabel(
              product.customFields?.targetPersons?.filter((o) => !!o) || []
            )}
          </div>
          <div className="ow-break-word">
            {product.customFields?.targetPersonNote || ''}
          </div>
        </Typography>
      </div>
      {product.customFields?.freeContents?.map((content) => {
        return (
          <div>
            <Typography variant="h6">{content?.title || ''}</Typography>
            <Typography variant="body2" className="ow-break-word">
              {content?.value || ''}
            </Typography>
          </div>
        );
      })}
      <div>
        <Typography variant="h6">病院参加料金</Typography>
        <Typography variant="body2" className="ow-break-word">
          {product.customFields?.entryFee || ''}
        </Typography>
      </div>
    </Stack>
  );
}
