import { useCallback, useState, useEffect } from 'react';

import {
  getOrder,
  getOrders,
  getOrdersByOrgId,
  updateOrder,
} from '@app/adapter/order-service';
import { CategoryName } from '@app/types/catalog';
import { Order, OrderUpdate } from '@app/types/order';
import { isError } from '@app/utils/error';

export const useOrder = (id?: string) => {
  const [isLoading, setLoading] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  /**
   * Orderを取得してセットする
   */
  const [order, setOrder] = useState<Order>();
  const fetchOrder = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const { data } = await getOrder(id, {
        expand: 'lineItems.product,customer.user',
      });
      setOrder(data);
      return data;
    } catch (error) {
      if (isError(error)) {
        console.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const [subOrders, setSubOrders] = useState<Order[]>([]);
  const fetchSubOrders = useCallback(
    async (groupKeys: string[], organizationId?: string) => {
      if (!groupKeys.length) {
        return [];
      }

      setSubOrders([]);
      setLoading(true);

      const payload = {
        expand: 'lineItems.product,customer.user',
        filter: {
          category: CategoryName.ORIENTATION_SUB,
          orientationGroupKey: groupKeys,
        },
        pageSize: 100,
      };

      try {
        const { data } = organizationId
          ? await getOrdersByOrgId(organizationId, payload)
          : await getOrders(payload);
        setSubOrders(data.value);
        return data;
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (id) {
      void fetchOrder(id);
    }
  }, [id, fetchOrder]);

  /**
   * 更新APIにリクエストする
   */
  const update = async (orgId: string, id: string, payload: OrderUpdate) => {
    setUpdating(true);
    try {
      return await updateOrder(orgId, id, payload);
    } catch (error) {
      if (isError(error)) {
        console.error(error.message);
        throw new Error(error.message);
      }
    } finally {
      setUpdating(false);
    }
  };

  return {
    fetchOrder,
    fetchSubOrders,
    isLoading,
    isUpdating,
    order,
    subOrders,
    update,
  };
};
