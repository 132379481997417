import { LoadingButton } from '@mui/lab';
import { ButtonProps, Typography } from '@mui/material';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { useOrientationProduct } from '@app/administrator/hooks/useOrientationProduct';
import { SimpleDialog } from '@app/components/Shared/Dialog/SimpleDialog';
import { FormDateField } from '@app/components/Shared/Inputs/FormDateField';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import {
  OrientationStatusEditForm as Form,
  OrientationStatusEditFormData as FormData,
} from '@app/schemas/catalog';
import {
  Product,
  ProductCustomFields,
  ProductPublication,
  ProductStatus as Status,
  ProductSubStatus as SubStatus,
  ProductSubStatusKey as SubStatusKey,
} from '@app/types/catalog';
import { isError } from '@app/utils/error';

export interface OrientationSubStatusButtonProps
  extends Omit<ButtonProps, 'onChange'> {
  label?: string;
  onChange?: (product?: Product) => void;
  product: Product;
  subStatus: SubStatusKey;
}

export function OrientationSubStatusButton({
  label,
  onChange,
  product,
  subStatus,
  ...props
}: OrientationSubStatusButtonProps): ReactElement {
  const setSnackbar = useSetSnackbar();
  const { fetchChildProducts, isUpdating, updateStatus } =
    useOrientationProduct();
  const [isPublishModal, setPublishModal] = useState(false);

  const form = useForm<FormData>(Form);
  const {
    formState: { isValid },
    getValues,
    reset,
  } = form;

  const handleClickCancel = useCallback(() => {
    setPublishModal(false);
    reset();
  }, [setPublishModal, reset]);

  const handleClickSubmit = useCallback(async () => {
    const customFields: ProductCustomFields = {
      subStatus,
      supplyConfirmClosingDate: undefined,
    };
    const publication: ProductPublication = {
      status: undefined,
      until: undefined,
    };

    try {
      switch (subStatus) {
        case SubStatus.CONFIRM: {
          // 未対応の応募チェック
          const applyData = await fetchChildProducts(product.id, {
            filter: { subStatus: SubStatus.APPLY },
            pageSize: 1,
          });
          if (applyData?.length) {
            throw new Error('まだ未許可の応募があります');
          }

          customFields.supplyConfirmClosingDate = getValues(
            'supplyConfirmClosingDate'
          );
          break;
        }
        case SubStatus.DEMAND_CLOSED:
          publication.status = Status.DRAFT;
          publication.until = new Date().toISOString();
          break;
        case SubStatus.ABSENT:
          publication.status = Status.DRAFT;
          break;
      }

      const latest = await updateStatus({ customFields, publication }, product);
      if (latest) {
        setSnackbar(true, '更新しました', 'success');
        handleClickCancel();
      }
      onChange?.(latest);
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
        setSnackbar(true, e.message, 'error');
      }
    }
  }, [
    onChange,
    product,
    subStatus,
    updateStatus,
    getValues,
    handleClickCancel,
    setSnackbar,
    fetchChildProducts,
  ]);

  const handleClickButton = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      switch (subStatus) {
        case SubStatus.RECRUITING:
        case SubStatus.SUPPLY_CLOSED:
        case SubStatus.CONFIRM:
        case SubStatus.DEMAND_CLOSED:
        case SubStatus.ABSENT:
          setPublishModal(true);
          break;
        default:
          void handleClickSubmit();
      }
    },
    [handleClickSubmit, subStatus]
  );

  const buttonLabel = useMemo(() => {
    reset({ subStatus });
    if (label) {
      return label;
    }
    switch (subStatus) {
      case SubStatus.RECRUITING:
        return '病院募集する';
      case SubStatus.SUPPLY_CLOSED:
      case SubStatus.DEMAND_CLOSED:
        return '締切る';
      case SubStatus.CONFIRM:
        return '依頼する';
      case SubStatus.SURVEY:
        return '配布する';
      case SubStatus.APPROVE:
        return '参加許可';
      case SubStatus.CANCEL:
      case SubStatus.REJECT:
        return '辞退/拒否';
      case SubStatus.ABSENT:
        return '欠席にする';
    }
    return '';
  }, [label, subStatus, reset]);

  const modalTitle = useMemo(() => {
    switch (subStatus) {
      case SubStatus.RECRUITING:
        return 'この内容で公開しますか？';
      case SubStatus.SUPPLY_CLOSED:
        return '病院応募を締め切りますか？';
      case SubStatus.CONFIRM:
        return '参加希望病院へ説明会内容の確認を依頼する';
      case SubStatus.DEMAND_CLOSED:
        return '一般利用者の参加応募を締め切りますか？';
      case SubStatus.ABSENT:
        return `${product.organization.name}を欠席にしますか？`;
    }
    return '';
  }, [subStatus, product]);

  return (
    <>
      <LoadingButton
        variant="outlined"
        color="tertiary"
        loading={isUpdating}
        onClick={handleClickButton}
        {...props}
      >
        {buttonLabel}
      </LoadingButton>
      <SimpleDialog
        open={isPublishModal}
        title={modalTitle}
        submitLabel={buttonLabel}
        cancelLabel="キャンセル"
        disabled={!isValid}
        loading={isUpdating}
        fullWidth
        hideContent={
          !(
            subStatus === SubStatus.CONFIRM ||
            subStatus === SubStatus.DEMAND_CLOSED
          )
        }
        onClickSubmit={handleClickSubmit}
        onClickCancel={handleClickCancel}
      >
        <FormProvider {...form}>
          <form>
            {subStatus === SubStatus.CONFIRM && (
              <FormDateField
                name="supplyConfirmClosingDate"
                title="確認締め切り日"
                popperPlacement="right-start"
                required
              />
            )}
          </form>
        </FormProvider>
        {subStatus === SubStatus.DEMAND_CLOSED && (
          <div>
            設定されている
            <Typography color="error" component="span">
              zoomのURLが一般利用者にメールで通知されます。
            </Typography>
            変更が必要な場合は編集してから締め切りをしてください。
          </div>
        )}
      </SimpleDialog>
    </>
  );
}
