import { Global, css } from '@emotion/react';
import { Article as ArticleIcon } from '@mui/icons-material';
import {
  Box,
  Drawer,
  Link as MuiLink,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
  Badge,
} from '@mui/material';
import { ReactElement, useEffect, useMemo } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { Backdrop } from '@app/components/Shared/Backdrop';
import { Header, headerHeight } from '@app/components/Shared/Header';
import { Snackbar } from '@app/components/Shared/Snackbar';
import { AppContext } from '@app/hooks/appContext';

export const menuWidth = 256;

export function VendorLayout(): ReactElement {
  const theme = useTheme();
  const location = useLocation();

  const menuItems = useMemo(() => {
    const menus: {
      children: {
        badgeCount?: number;
        hidden?: boolean;
        icon?: ReactElement;
        label: string;
        path: string;
        selectedPath?: RegExp;
      }[];
      groupLabel?: string;
    }[] = [
      {
        children: [
          {
            icon: <ArticleIcon />,
            label: '説明会管理',
            path: '/orientation-products',
            selectedPath: /^\/orientation-products(\/.*)?$/,
          },
        ],
      },
      {
        children: [
          {
            icon: <ArticleIcon />,
            label: '説明会応募者一覧',
            path: '/orientation-orders',
            selectedPath: /^\/orientation-orders(\/.*)?$/,
          },
        ],
      },
    ];

    return menus
      .map((menu) => {
        return {
          ...menu,
          children: menu.children.filter((child) => !child.hidden),
        };
      })
      .filter((menu) => !!menu.children.length);
  }, []);

  useEffect(() => {
    async function execute() {}
    void execute();
    // polling
    const intervalId = setInterval(() => {
      void execute();
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <Global
        styles={css`
          body {
            background-color: ${theme.customPalette.gray3};
          }
        `}
      />
      <Snackbar />
      <Backdrop />
      <Header />
      <Drawer
        variant="permanent"
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: menuWidth,
          },
          flexShrink: 0,
          width: menuWidth,
        }}
      >
        <Toolbar sx={{ height: headerHeight }} />
        <List sx={{ overflow: 'auto', py: 0 }}>
          {menuItems.map((item, index) => (
            <Box key={index}>
              {item.groupLabel && (
                <Typography
                  component="div"
                  variant="body3"
                  sx={{
                    backgroundColor: theme.customPalette.blue2,
                    px: 2,
                    py: 1,
                  }}
                >
                  {item.groupLabel}
                </Typography>
              )}
              {item.children?.map((child, index) => (
                <MuiLink
                  key={index}
                  component={Link}
                  to={child.path}
                  color="inherit"
                  underline="none"
                >
                  <ListItemButton
                    selected={
                      child.selectedPath &&
                      !!location.pathname.match(child.selectedPath)
                    }
                    sx={{ height: 56 }}
                  >
                    {child?.icon && (
                      <ListItemIcon sx={{ color: 'inherit', minWidth: 40 }}>
                        {child.icon}
                      </ListItemIcon>
                    )}
                    {child.badgeCount ? (
                      <Badge
                        badgeContent={child.badgeCount}
                        color="error"
                        max={99}
                        overlap="circular"
                        sx={{
                          '.MuiBadge-badge': {
                            right: 0,
                            transform: 'translate(0)',
                          },
                          width: '100%',
                        }}
                      >
                        <ListItemText primary={child.label} />
                      </Badge>
                    ) : (
                      <ListItemText primary={child.label} />
                    )}
                  </ListItemButton>
                </MuiLink>
              ))}
            </Box>
          ))}
        </List>
      </Drawer>
      <Box component="main" flexGrow={1}>
        <Toolbar sx={{ height: headerHeight }} />
        <Box p={4}>
          <AppContext.Provider value={undefined}>
            <Outlet />
          </AppContext.Provider>
        </Box>
      </Box>
    </Box>
  );
}
