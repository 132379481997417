import { Box, Grid, Paper, Toolbar } from '@mui/material';
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { Backdrop } from '@app/components/Shared/Backdrop';
import { Header, headerHeight } from '@app/components/Shared/Header';
import { Snackbar } from '@app/components/Shared/Snackbar';

export function DefaultLayout(): ReactElement {
  return (
    <>
      <Snackbar />
      <Backdrop />
      <Box>
        <Header />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Toolbar sx={{ height: headerHeight }} />
          <Grid
            direction="row"
            justifyContent="center"
            container
            sx={{ my: 5 }}
          >
            <Paper variant="outlined" sx={{ px: 5.5, py: 7, width: 416 }}>
              <Outlet />
            </Paper>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
