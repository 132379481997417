import { Organization } from '@app/types/organization';
import { DesiredJob } from '@app/types/user';

/**
 * Product
 */
export interface Product {
  additionalInformation?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  attributes?: (ProductAttribute | Attribute)[];
  category: Category;
  categoryId: string;
  children?: Product[];
  createdAt: string;
  customFields?: ProductCustomFields;
  delFlg: number;
  description?: string;
  dimensions?: string;
  features?: ProductFeature[];
  geoCoordinatesField?: ProductGeoCoordinatesField;
  id: string;
  imageIds?: string[];
  images?: ProductImage[];
  locationIds?: string[];
  locations?: ProductLocation[];
  name: string;
  organization: Organization;
  organizationId: string;
  postalCode?: string;
  publication: ProductPublication;
  tags?: string[];
  updatedAt: string;
  variantNote?: string;
  variants?: ProductVariant[];
}

export interface ProductCustomFields {
  access?: string;
  accommodationFee?: string;
  branchNote?: string;
  branches?: string[];
  category?: CategoryNameKey;
  dayOff?: string;
  days?: string;
  daysNote?: string;
  employeeBenefit?: string;
  entryFee?: string;
  etcFee?: string;
  freeContents?: ProductFreeContent[];
  locationType?: ProductLocationTypeKey;
  note?: string;
  occupation?: string;
  orderCount?: number;
  orderMaxCount?: number;
  orientationType?: ProductOrientationTypeKey;
  parentId?: string;
  recruitNote?: string;
  recruitSellingPoint?: string;
  salary?: string;
  schedules?: ProductSchedule[];
  sellingPoint?: string;
  subStatus?: ProductSubStatusKey;
  supplyConfirmClosingDate?: string;
  supplyRecruitClosingDate?: string;
  survey?: string;
  surveyResponseCount?: number;
  targetPersonNote?: string;
  targetPersons?: string[];
  transportationFee?: string;
  transportationFeeNote?: string;
  workingHour?: string;
}

export interface ProductPublication {
  since?: string;
  status?: ProductStatusKey;
  until?: string;
}
export type ProductPublicationInput = Partial<
  Omit<ProductPublication, 'publishedAt' | 'publishedBy'>
>;

export interface ProductGeoCoordinatesField {
  coordinates: number[];
  type: ProductGeoCoordinatesFieldTypeKey;
}

export interface ProductFeature {
  name: string;
  value: string;
}

export interface ProductImage {
  id?: string;
  type?: string;
  url: string;
}

export interface ProductLocation {
  children?: ProductLocation[];
  code: string;
  createdAt: string;
  id: string;
  name: string;
  parent: string;
  type: LocationTypeKey;
  updatedAt: string;
}

export type ProductAttribute = {
  attributeId: string;
  value: string;
};

export interface ProductSchedule {
  date?: string;
  note?: string;
  occupations?: ProductScheduleOccupationKey[];
  orgMaxCount?: number;
  timeNote?: string;
  times?: ScheduleTime[];
  totalApplyCount?: number;
  zoom?: string;
}

export interface ScheduleTime {
  end?: string;
  start?: string;
}

export interface ProductFreeContent {
  title?: string;
  value?: string;
}

export type ProductCreate = Omit<
  Product,
  | 'attributes'
  | 'category'
  | 'createdAt'
  | 'delFlg'
  | 'id'
  | 'images'
  | 'locations'
  | 'organization'
  | 'organizationId'
  | 'updatedAt'
  | 'variants'
> & {
  attributes?: ProductAttribute[];
  imageIds?: string[];
  locationIds?: string[];
  variants?: ProductVariantCreate[];
};

export type ProductUpdate = Partial<ProductCreate>;

export const ProductStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
} as const;
export type ProductStatusKey =
  (typeof ProductStatus)[keyof typeof ProductStatus];

export const ProductGeoCoordinatesFieldType = {
  POINT: 'Point',
} as const;
export type ProductGeoCoordinatesFieldTypeKey =
  (typeof ProductGeoCoordinatesFieldType)[keyof typeof ProductGeoCoordinatesFieldType];

export const ProductSubStatus = {
  ABSENT: 'absent', //欠席
  APPLY: 'apply', //応募
  APPROVE: 'approve', //許可
  CANCEL: 'cancel', //辞退
  CONFIRM: 'confirm', //確認依頼
  DEMAND_CLOSED: 'demandClosed', //一般締切
  DRAFT: 'draft', //下書き
  RECRUITING: 'recruiting', //募集
  REJECT: 'reject', //却下
  SUPPLY_CLOSED: 'supplyClosed', //病院締切
  SURVEY: 'survey', //アンケート配布
} as const;
export type ProductSubStatusKey =
  (typeof ProductSubStatus)[keyof typeof ProductSubStatus];

export const ProductOrientationType = {
  OFFLINE: 'offline', // 現地
  ONLINE: 'online', // オンライン
} as const;
export type ProductOrientationTypeKey =
  (typeof ProductOrientationType)[keyof typeof ProductOrientationType];

export const ProductLocationType = {
  ALL: 'all', // 全国
  AREA: 'area', // エリア
  PREFECTURE: 'prefecture', // 都道府県
} as const;
export type ProductLocationTypeKey =
  (typeof ProductLocationType)[keyof typeof ProductLocationType];

export const ProductTargetPerson = {
  EXPERIENCED_QUALIFIED: 'experiencedQualified', //経験者
  GRADUATION_QUALIFIED: 'graduationQualified', //既卒（有資格）
  GRADUATION_UNQUALIFIED: 'graduationUnqualified', //既卒（無資格）
  PET_NURSING_STUDENT: 'petNursingStudent', //愛玩動物看護学生
  VETERINARY_STUDENT: 'veterinaryStudent', //獣医学生
} as const;
export type ProductTargetPersonKey =
  (typeof ProductTargetPerson)[keyof typeof ProductTargetPerson];

export const ProductScheduleOccupation: {
  [key: string]: string;
} = DesiredJob;
export type ProductScheduleOccupationKey =
  (typeof ProductScheduleOccupation)[keyof typeof ProductScheduleOccupation];

/**
 * Product Variant
 */
export interface ProductVariant {
  createdAt: string;
  customFields?: VariantCustomFields;
  description?: string;
  id: string;
  images?: VariantImage[];
  price: VariantPrice;
  productId: string;
  sku?: ProductVariantSkuKey;
  title: string;
  updatedAt: string;
}

export interface VariantPrice {
  amount: number;
  currency?: string;
  taxIncluded?: boolean;
  taxable?: boolean;
}

export interface VariantImage {
  id: string;
  type: string;
  url: string;
}

export interface VariantCustomFields {
  buildingArea?: string;
  category?: string;
  comment?: string;
  landArea?: string;
  price?: string;
}

export type ProductVariantCreate = Omit<
  ProductVariant,
  'id' | 'productId' | 'images' | 'createdAt' | 'updatedAt'
> & {
  imageIds?: string[];
};
export type ProductVariantsCreate = ProductVariantCreate & {
  productId: string;
};

export type ProductVariantUpdate = ProductVariantCreate;
export interface ProductVariantsUpdate {
  data: ProductVariantUpdate;
  ids: string[];
}

export interface ProductVariantsDelete {
  ids: string[];
}

export const ProductVariantSku = {} as const;
export type ProductVariantSkuKey =
  (typeof ProductVariantSku)[keyof typeof ProductVariantSku];

/**
 * Attribute
 */
export interface Attribute {
  attributeId?: string;
  categoryIds: string[];
  children?: Attribute[];
  customFields?: AttributeCustomFields;
  groupName: string;
  id: string;
  items: AttributeItem[];
  name: string;
  order: number;
  type: string;
}

export interface AttributeCustomFields {
  icon?: {
    type: string;
    value: string;
  };
  keywords?: string;
}

export interface AttributeItem {
  groupName: string;
  key: string;
  value: string;
}

export const AttributeGroupName = {
  SMAVERSE: 'smaverse', // スマバース
} as const;
export type AttributeGroupNameKey =
  (typeof AttributeGroupName)[keyof typeof AttributeGroupName];

/**
 * Category
 */
export interface Category {
  children: Category[];
  description: string;
  id?: string;
  name: CategoryNameKey;
  numberOfProducts?: number;
  parent: null | {
    uri: string;
  };
  status: CategoryStatusKey;
}

export interface SubCategory {
  description: string;
  id?: string;
  name: string;
  numberOfProducts?: number;
  status: string;
}
export type CategoryInput = Omit<Category, 'id'>;

export const CategoryStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
} as const;
export type CategoryStatusKey =
  (typeof CategoryStatus)[keyof typeof CategoryStatus];

export const CategoryName = {
  INTERNSHIP: 'internship', //実習
  ORIENTATION: 'orientation', //説明会
  ORIENTATION_SUB: 'orientationSub', //説明会サブ
} as const;
export type CategoryNameKey = (typeof CategoryName)[keyof typeof CategoryName];

/**
 * Location
 */
export interface LocationListResponse {
  '@nextLink': string;
  count: number;
  total: number;
  value: Array<Location>;
}

export interface Location {
  code: string;
  createdAt: string;
  id: string;
  name: string;
  parent: string;
  type: LocationTypeKey;
  updatedAt: string;
}

export const LocationType = {
  CITY: 'city',
  PREFECTURE: 'prefecture',
  REGION: 'region',
} as const;
export type LocationTypeKey = (typeof LocationType)[keyof typeof LocationType];

/**
 * attachment
 */
export interface Attachment {
  id: string;
  isPublic: boolean;
  ownerId: string;
  ownerType: AttachmentOwnerTypeKey;
  type: string;
  url: string;
}

export interface AttachmentCreate {
  objectId: string;
  ownerId?: string;
  type: string; //ファイル形式
}

export const AttachmentOwnerType = {
  ORGANIZATION: 'organization',
} as const;
export type AttachmentOwnerTypeKey =
  (typeof AttachmentOwnerType)[keyof typeof AttachmentOwnerType];
