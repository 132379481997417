import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { resetPassword } from '@app/adapter/user-service';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { PasswordResetForm, PasswordResetFormData } from '@app/schemas/user';
import { isError } from '@app/utils/error';

export function PasswordForm(): ReactElement {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<PasswordResetFormData>(PasswordResetForm);

  const handleSubmitForm = async (formData: PasswordResetFormData) => {
    try {
      setIsLoading(true);

      const token = new URLSearchParams(globalThis.location.search).get(
        'token'
      );
      if (!token) {
        throw new Error('設定に必要なトークンがありません');
      }
      await resetPassword(formData.password, token);

      setSnackbar(true, 'パスワードを設定しました', 'success');
      navigate('/login');
    } catch (error) {
      if (isError(error)) {
        console.error(error.message);
        setSnackbar(true, 'パスワードの登録に失敗しました', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack spacing={4}>
        <Typography variant="h6" textAlign="center">
          パスワード再設定
        </Typography>
        <Typography>新しいパスワードを入力して下さい。</Typography>
        <FormControl fullWidth>
          <FormLabel focused={false}>新しいパスワード</FormLabel>
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="password"
                error={!!error}
                helperText={error?.message}
                placeholder="パスワードを入力"
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel focused={false}>新しいパスワードの確認</FormLabel>
          <Controller
            name="passwordConfirm"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="password"
                error={!!error}
                helperText={error?.message}
                placeholder="同じパスワードを入力"
              />
            )}
          />
        </FormControl>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          loading={isLoading}
          disabled={!isValid}
          fullWidth
        >
          送信
        </LoadingButton>
      </Stack>
    </form>
  );
}
