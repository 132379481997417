import { AxiosPromise } from 'axios';
import { format as dateFnsFormat } from 'date-fns';

import { ORDER_SERVICE, axiosInstance } from '@app/adapter/axios';
import {
  CategoryNameKey,
  ProductOrientationTypeKey as OrientationTypeKey,
} from '@app/types/catalog';
import { GetListDataRequest, Paginated } from '@app/types/common';
import {
  Order,
  OrderStatus,
  OrderStats,
  OrderStatusKey,
  OrderUpdate,
} from '@app/types/order';
import { filterSyntaxGen } from '@app/utils';
import { convertUtcToJp } from '@app/utils/format';

/**
 * Order
 */
export type GetOrdersOption = Partial<
  GetListDataRequest & {
    filter: {
      category?: CategoryNameKey;
      createdAt?: string[]; //0:~から, 1:~まで
      keyword?: string;
      orientationGroupKey?: string | string[];
      orientationType?: OrientationTypeKey;
      status?: OrderStatusKey | OrderStatusKey[];
    };
  }
>;

export function getOrdersByOrgId(
  organizationId?: string,
  options?: GetOrdersOption
): AxiosPromise<Paginated<Order>> {
  if (options?.nextLink || options?.previousLink) {
    return axiosInstance
      .get(options.nextLink || options.previousLink || '')
      .catch((error) => {
        if ('message' in error.response.data) {
          throw new Error(error.response?.data.message);
        } else {
          throw new Error(error.message);
        }
      });
  }

  const pageSize = options?.pageSize || 10;
  const page = options?.page || 0;
  const urlParams = [
    ['$top', pageSize.toString()],
    ['$skip', (page * pageSize).toString()],
  ];

  const filterParam = [];
  if (options?.filter?.status?.length) {
    const statuses = Array.isArray(options.filter.status)
      ? options.filter.status
      : [options.filter.status];
    filterParam.push(`status in ${filterSyntaxGen(statuses)}`);
  }
  if (options?.filter?.orientationGroupKey?.length) {
    const orientationGroupKey = Array.isArray(
      options.filter.orientationGroupKey
    )
      ? options.filter.orientationGroupKey
      : [options.filter.orientationGroupKey];
    filterParam.push(
      `customFields.orientationGroupKey in ${filterSyntaxGen(
        orientationGroupKey
      )}`
    );
  }
  if (options?.filter?.createdAt) {
    if (options.filter.createdAt[0]) {
      filterParam.push(
        `ymd(createdAt) ge '${dateFnsFormat(
          convertUtcToJp(options.filter.createdAt[0]),
          'yyyy-MM-dd'
        )}'`
      );
    }
    if (options.filter.createdAt[1]) {
      filterParam.push(
        `ymd(createdAt) le '${dateFnsFormat(
          convertUtcToJp(options.filter.createdAt[1]),
          'yyyy-MM-dd'
        )}'`
      );
    }
  }
  if (options?.filter?.keyword) {
    const queryFields = [
      'customer.name',
      'customer.email',
      'customer.phone',
      'lineItems.productName',
    ];
    const query = queryFields
      .map((field) => `${field} co '${options.filter?.keyword}'`)
      .join(' or ');
    filterParam.push(`(${query})`);
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  urlParams.push(['$orderBy', options?.orderBy || 'createdAt desc']);

  return axiosInstance
    .get(
      `${ORDER_SERVICE}/orgs/${organizationId}/orders?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getOrders(
  options?: GetOrdersOption
): AxiosPromise<Paginated<Order>> {
  if (options?.nextLink || options?.previousLink) {
    return axiosInstance
      .get(options.nextLink || options.previousLink || '')
      .catch((error) => {
        if ('message' in error.response.data) {
          throw new Error(error.response?.data.message);
        } else {
          throw new Error(error.message);
        }
      });
  }

  const pageSize = options?.pageSize || 10;
  const page = options?.page || 0;
  const urlParams = [
    ['$top', pageSize.toString()],
    ['$skip', (page * pageSize).toString()],
  ];

  const filterParam = [];
  if (options?.filter?.category) {
    filterParam.push(
      `customFields.productCategory eq '${options.filter.category}'`
    );
  }
  if (options?.filter?.orientationType) {
    filterParam.push(
      `customFields.orientationType eq '${options.filter.orientationType}'`
    );
  }
  if (options?.filter?.orientationGroupKey?.length) {
    const orientationGroupKey = Array.isArray(
      options.filter.orientationGroupKey
    )
      ? options.filter.orientationGroupKey
      : [options.filter.orientationGroupKey];
    filterParam.push(
      `customFields.orientationGroupKey in ${filterSyntaxGen(
        orientationGroupKey
      )}`
    );
  }
  if (options?.filter?.status?.length) {
    const statuses = Array.isArray(options.filter.status)
      ? options.filter.status
      : [options.filter.status];
    filterParam.push(`status in ${filterSyntaxGen(statuses)}`);
  }
  if (options?.filter?.keyword) {
    const queryFields = [
      'customer.name',
      'customer.email',
      'customer.phone',
      'lineItems.productName',
    ];
    const query = queryFields
      .map((field) => `${field} co '${options.filter?.keyword}'`)
      .join(' or ');
    filterParam.push(`(${query})`);
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  urlParams.push(['$orderBy', options?.orderBy || 'createdAt desc']);

  return axiosInstance
    .get(`${ORDER_SERVICE}/orders?${new URLSearchParams(urlParams).toString()}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getOrderByOrgId(
  organizationId: string,
  orderId: string,
  options?: {
    expand?: string;
  }
): AxiosPromise<Order> {
  const urlParams: string[][] = [];
  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  return axiosInstance
    .get(
      `${ORDER_SERVICE}/orgs/${organizationId}/orders/${orderId}?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getOrder(
  id: string,
  options?: {
    expand?: string;
  }
): AxiosPromise<Order> {
  const urlParams: string[][] = [];
  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  urlParams.push(['$filter', `id eq '${id}'`]);
  return axiosInstance
    .get(`${ORDER_SERVICE}/orders?${new URLSearchParams(urlParams).toString()}`)
    .then((response) => {
      return {
        ...response,
        data: response.data.value[0],
      };
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function updateOrder(
  organizationId: string,
  orderId: string,
  payload: OrderUpdate
): AxiosPromise<void> {
  return axiosInstance
    .patch(`${ORDER_SERVICE}/orgs/${organizationId}/orders/${orderId}`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteOrder(
  organizationId: string,
  selectedId: string
): AxiosPromise<void> {
  return axiosInstance
    .patch(`${ORDER_SERVICE}/orgs/${organizationId}/orders/${selectedId}`, {
      status: OrderStatus.CANCELED,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getOrderStats(
  organizationId: string,
  productIds: string[]
): AxiosPromise<OrderStats[]> {
  const params = new URLSearchParams();

  if (productIds && productIds.length > 0) {
    params.append('productIds', productIds.join(','));
  }

  return axiosInstance
    .get(
      `${ORDER_SERVICE}/orgs/${organizationId}/products/stats/orders?${params}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function updateOrderStatus(
  organizationId: string,
  selectedId: string,
  status: OrderStatusKey
): AxiosPromise<void> {
  return axiosInstance
    .patch(`${ORDER_SERVICE}/orgs/${organizationId}/orders/${selectedId}`, {
      status,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * CSVファイル情報を取得
 */
export function getCsvFile(options?: {
  createdAt?: string[]; //0:~から, 1:~まで
  orderBy?: string;
  orgId?: string;
  productCategory?: CategoryNameKey;
  status?: OrderStatusKey | OrderStatusKey[];
}): AxiosPromise<string> {
  const urlParams: string[][] = [];

  const filterParam = [];
  if (options?.status?.length) {
    const status = Array.isArray(options.status)
      ? options.status
      : [options.status];
    filterParam.push(`status in ${filterSyntaxGen(status)}`);
  }
  if (options?.createdAt) {
    if (options.createdAt[0]) {
      filterParam.push(
        `ymd(createdAt) ge '${dateFnsFormat(
          convertUtcToJp(options.createdAt[0]),
          'yyyy-MM-dd'
        )}'`
      );
    }
    if (options.createdAt[1]) {
      filterParam.push(
        `ymd(createdAt) le '${dateFnsFormat(
          convertUtcToJp(options.createdAt[1]),
          'yyyy-MM-dd'
        )}'`
      );
    }
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  if (options?.orgId) {
    urlParams.push(['orgId', options.orgId]);
  }
  if (options?.productCategory) {
    urlParams.push(['productCategory', options.productCategory]);
  }

  urlParams.push(['$orderBy', options?.orderBy || 'createdAt desc']);

  return axiosInstance
    .get(
      `${ORDER_SERVICE}/csv/orders?${new URLSearchParams(urlParams).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
