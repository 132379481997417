import { Box, Card, Stack, Typography } from '@mui/material';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  getAdminProducts,
  getProducts,
  GetProductsOption,
} from '@app/adapter/catalog-service';
import { occupationOptions } from '@app/administrator/components/Catalog/OrientationScheduleForm';
import { OrientationApplyButton } from '@app/components/Catalog/OrientationApplyButton';
import { OrientationSubStatusButton as SubStatusButton } from '@app/components/Catalog/OrientationSubStatusButton';
import { DetailButton } from '@app/components/Shared/Button/DetailButton';
import { ButtonTabs, ButtonTabsProps } from '@app/components/Shared/ButtonTabs';
import { FormSelect } from '@app/components/Shared/Inputs/FormSelect';
import { ListTable, paginationModel } from '@app/components/Shared/ListTable';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { SearchTextBox } from '@app/components/Shared/SearchTextBox';
import { organization } from '@app/domain/organization';
import {
  ProductSearchForm as SearchForm,
  ProductSearchFormData as SearchFormData,
} from '@app/schemas/catalog';
import { theme } from '@app/theme';
import {
  Product,
  ProductSubStatus as SubStatus,
  ProductSubStatusKey as SubStatusKey,
  CategoryName as Category,
  ProductSchedule as Schedule,
  ProductOrientationType as OrientationType,
} from '@app/types/catalog';
import { getOccupationsLabel, getLocationLabel } from '@app/utils/catalog';
import {
  ORIENTATION_OCCUPATION_COUNT_MAX as OCCUPATION_COUNT_MAX,
  SORT_OPTIONS,
} from '@app/utils/constants';
import { isError } from '@app/utils/error';
import { dateFormat } from '@app/utils/format';

export function OrientationIndex() {
  const navigate = useNavigate();
  const currentOrg = useRecoilValue(organization);

  const [tabs, setTabs] = useState<ButtonTabsProps['tabs']>([
    {
      batch: 0,
      label: '新規募集',
      value: SubStatus.RECRUITING,
    },
    { batch: 0, label: '応募一覧', value: SubStatus.APPLY },
    { batch: 0, label: '参加確認', value: SubStatus.CONFIRM },
    {
      batch: 0,
      label: '参加決定',
      value: SubStatus.APPROVE,
    },
  ]);
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<SearchFormData>(SearchForm);
  const { control, reset } = form;
  const formWatch = useWatch({ control });
  const [keyword, setKeyword] = useState('');

  const [rows, setRows] = useState<Product[]>([]);
  const [rowTotal, setRowTotal] = useState<number>(0);
  const [currentPaginationModel, setCurrentPaginationModel] =
    useState<GridPaginationModel>(paginationModel);

  const fetchProducts = useCallback(
    async (tab?: string, option?: GetProductsOption) => {
      if (!currentOrg) return;

      try {
        const selectTab = tab || selectedTab;
        if (selectTab === SubStatus.RECRUITING) {
          const { data } = await getAdminProducts(currentOrg.id, {
            ...option,
            filter: {
              category: Category.ORIENTATION,
              isApply: false,
              subStatus: SubStatus.RECRUITING,
              ...option?.filter,
            },
          });
          return data;
        }

        const { data: subData } = await getProducts(currentOrg.id, {
          ...option,
          filter: {
            category: Category.ORIENTATION_SUB,
            subStatus: selectTab as SubStatusKey,
            ...option?.filter,
          },
        });

        if (subData.count && !tab) {
          const { data } = await getAdminProducts(currentOrg.id, {
            filter: {
              id: subData.value
                .map((v) => v.customFields?.parentId)
                .filter((i) => !!i) as string[],
            },
            pageSize: 100,
          });
          return {
            ...data,
            value: subData.value
              .map((sub) => {
                const product = data.value.find(
                  (v) => v.id === sub.customFields?.parentId
                );
                return product
                  ? {
                      ...product,
                      customFields: {
                        ...product?.customFields,
                        schedules: sub.customFields?.schedules,
                        subStatus: sub.customFields?.subStatus,
                      },
                    }
                  : undefined;
              })
              .filter((i) => !!i) as Product[],
          };
        }
        return subData;
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
      }
    },
    [currentOrg, selectedTab]
  );

  const rowRefresh = useCallback(async () => {
    setIsLoading(true);
    const data = await fetchProducts(undefined, {
      expand: 'totalApplyCount',
      filter: {
        keyword,
        occupation: formWatch.occupation,
      },
      orderBy: formWatch.sort,
      ...currentPaginationModel,
    });
    setRows(data?.value || []);
    setRowTotal(data?.total || 0);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPaginationModel, formWatch, keyword]);

  useEffect(() => {
    void rowRefresh();
  }, [rowRefresh]);

  const updateTabBatches = useCallback(async () => {
    const updatedTabs = await Promise.all(
      tabs.map(async (tab) => {
        const data = await fetchProducts(tab.value, { pageSize: 1 });
        return { ...tab, batch: data?.total || 0 };
      })
    );
    setTabs(updatedTabs);
  }, [fetchProducts, tabs]);

  useEffect(() => {
    void updateTabBatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const initColumns: GridColDef[] = [
    {
      field: 'name',
      flex: 1,
      headerAlign: 'center',
      headerName: '説明会名',
      minWidth: 177,
      renderCell: ({ row }) => <div className="text-ellipsis">{row.name}</div>,
      sortable: false,
    },
    {
      align: 'center',
      field: 'address',
      headerAlign: 'center',
      headerName: '開催場所',
      renderCell: ({ row }) => (
        <Box
          sx={{
            color:
              row.customFields.orientationType === OrientationType.ONLINE
                ? theme.palette.primary.main
                : theme.customPalette.yellow2,
          }}
        >
          {getLocationLabel(row)}
        </Box>
      ),
      sortable: false,
      width: 100,
    },
    {
      align: 'center',
      field: 'occupation',
      headerAlign: 'center',
      headerName: '職種',
      renderCell: ({ row }) => (
        <Box className="ow-break-word" textAlign="center">
          {getOccupationsLabel(row.customFields?.schedules, '\n')}
        </Box>
      ),
      sortable: false,
      width: 170,
    },
    {
      align: 'center',
      field: 'occupationDemand',
      headerAlign: 'center',
      headerName: '職種(学生参加人数上限)',
      renderCell: ({ row }) => (
        <Box className="ow-break-word" textAlign="center">
          {getOccupationsLabel(row.customFields?.schedules, ',')
            .split(',')
            .map((occupation) => `${occupation}(${OCCUPATION_COUNT_MAX})`)
            .join('\n')}
        </Box>
      ),
      sortable: false,
      width: 180,
    },
    {
      align: 'center',
      field: 'supplyRecruitClosingDate',
      headerAlign: 'center',
      headerName: '応募締切日',
      renderCell: ({ row }) =>
        dateFormat(row.customFields?.supplyRecruitClosingDate),
      sortable: false,
      width: 140,
    },
    {
      align: 'center',
      field: 'scheduleDate',
      headerAlign: 'center',
      headerName: '開催日時/残り枠',
      renderCell: ({ row }) =>
        row.customFields?.schedules?.map(
          (schedule: Schedule, index: number) => (
            <div key={index}>{`${dateFormat(schedule.date)}(${
              (schedule?.orgMaxCount || 0) - (schedule?.totalApplyCount || 0)
            }枠)`}</div>
          )
        ) || '',
      sortable: false,
      width: 180,
    },
    {
      align: 'center',
      field: 'participationDate',
      headerAlign: 'center',
      headerName: '参加日',
      renderCell: ({ row }) => (
        <div className="ow-break-word">
          {row.customFields?.schedules
            ?.map((schedule: Schedule) => dateFormat(schedule?.date))
            .join('\n') || ''}
        </div>
      ),
      sortable: false,
      width: 140,
    },
    {
      align: 'center',
      field: 'supplyConfirmClosingDate',
      headerAlign: 'center',
      headerName: '確認締切日',
      renderCell: ({ row }) =>
        dateFormat(row.customFields?.supplyConfirmClosingDate),
      sortable: false,
      width: 140,
    },
    {
      align: 'center',
      field: 'orderCount',
      headerAlign: 'center',
      headerName: '参加人数',
      renderCell: ({ row }) =>
        row.customFields?.orderCount ? `${row.customFields.orderCount}人` : '-',
      sortable: false,
      width: 230,
    },
    {
      align: 'center',
      field: 'applyAction',
      headerAlign: 'center',
      headerName: '参加応募',
      renderCell: ({ row }) =>
        row.customFields?.subStatus === SubStatus.RECRUITING ? (
          <OrientationApplyButton
            color="primary"
            parentProduct={row}
            onChange={() => {
              void rowRefresh();
              void updateTabBatches();
            }}
            sx={{ width: 80 }}
          />
        ) : (
          <div>募集終了</div>
        ),
      sortable: false,
      width: 100,
    },
    {
      align: 'center',
      field: 'cancelAction',
      headerAlign: 'center',
      headerName: '参加取消',
      renderCell: ({ row }) => (
        <SubStatusButton
          color="tertiary"
          product={row}
          subStatus={SubStatus.CANCEL}
          onChange={() => {
            void rowRefresh();
            void updateTabBatches();
          }}
          sx={{ width: 80 }}
        />
      ),
      sortable: false,
      width: 100,
    },
    {
      align: 'center',
      field: 'detail',
      headerAlign: 'center',
      headerName: '詳細',
      renderCell: ({ row }) => (
        <DetailButton
          onClick={() => navigate(`/orientation-products/${row.id}`)}
        />
      ),
      sortable: false,
      width: 60,
    },
  ];

  const columns = useMemo(() => {
    return initColumns.filter((column) => {
      switch (selectedTab) {
        case SubStatus.RECRUITING:
          return [
            'name',
            'address',
            'occupation',
            'supplyRecruitClosingDate',
            'scheduleDate',
            'applyAction',
            'detail',
          ].includes(column.field);
        case SubStatus.APPLY:
          return [
            'name',
            'address',
            'occupationDemand',
            'supplyRecruitClosingDate',
            'participationDate',
            'cancelAction',
            'detail',
          ].includes(column.field);
        case SubStatus.CONFIRM:
          return [
            'name',
            'address',
            'occupationDemand',
            'participationDate',
            'supplyConfirmClosingDate',
            'cancelAction',
            'detail',
          ].includes(column.field);
        case SubStatus.APPROVE:
          return [
            'name',
            'address',
            'occupationDemand',
            'orderCount',
            'participationDate',
            'detail',
          ].includes(column.field);
      }
      return false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const handleChangeTab = useCallback(
    (value: string) => {
      setRows([]);
      setRowTotal(0);
      reset();
      setSelectedTab(value);
    },
    [reset]
  );

  return (
    <>
      <PageTitle title="説明会管理" buttonHidden />
      <ButtonTabs
        tabs={tabs}
        value={selectedTab}
        onChange={(e, value) => handleChangeTab(value)}
      />
      <Card>
        <FormProvider {...form}>
          <form style={{ width: '100%' }}>
            <Stack direction="row" spacing={1.5} p={1.5}>
              <SearchTextBox
                placeholder="キーワードで検索"
                value={keyword}
                onSubmit={setKeyword}
              />
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography variant="body3" width={50}>
                  並び順
                </Typography>
                <FormSelect name="sort" options={SORT_OPTIONS} />
              </Stack>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography variant="body3" width={34}>
                  職種
                </Typography>
                <FormSelect
                  name="occupation"
                  options={occupationOptions}
                  placeholder="すべて"
                />
              </Stack>
            </Stack>
          </form>
        </FormProvider>
        <ListTable
          columns={columns}
          rows={rows}
          rowCount={rowTotal}
          rowHeight={76}
          loading={isLoading}
          paginationModel={currentPaginationModel}
          className="b-radius-top-less"
          initialState={{
            pagination: { paginationModel },
          }}
          onPaginationModelChange={setCurrentPaginationModel}
        />
      </Card>
    </>
  );
}
